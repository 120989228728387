import React from "react";
import { connect } from "../../api/dapp";


import { onBlockNumberChange, onAccountChange, onChainSwitch, isInWeb3Browser } from "../../api/dapp";

class Connect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            provider: null,
            address: null,
            chainId: null,
            blocknumber: null,
        };
        this.doConnnect = this.doConnnect.bind(this);
    }

    async doConnnect() {
        let self = this;

        if (!isInWeb3Browser()) {
            return;
        }

        let res = await connect()

        this.setState({
            provider: res.provider,
            address: res.address,
            chainId: res.chainId,
            blocknumber: res.blocknumber
        }, () => {
            
        });
        // console.log("res address = " + res.address);
        if (this.props.onConnect) {
            this.props.onConnect(res);
        }
        onBlockNumberChange({
            provider: res.provider,
            success: (block) => {
                self.setState({
                    blocknumber: block
                });

                if (self.props.onBlockNumberChange) {
                    self.props.onBlockNumberChange(block);
                }
            }
        });
        onAccountChange({
            success: (accounts) => {
                self.setState({
                    address: accounts[0]
                });
                if (self.props.onAccountChange) {
                    self.props.onAccountChange(accounts);
                }
            }
        });
        onChainSwitch({
            success: (chainId) => {
                self.setState({
                    chainId: chainId
                });
                if (self.props.onChainSwitch) {
                    self.props.onChainSwitch(chainId);
                }
            }
        });
    }

    

    render() {
        
        const walletStyle = {
            backgroundColor: "#0B57FF",
            borderRadius: '20px',
            color: "#FFFFFF",
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingTop: '4px',
            paddingBottom: '4px',
            fontSize: '12px',
            display: 'flex',
            alignItems: 'center',
            // height: '20px'
            lineHeight: '2'
        }

        return (
            <div style={walletStyle}>
                <div>
                    {!this.state.address ? (<div onClick={this.doConnnect}>Connect Wallet</div>) : (
                        <div>
                            <div>{this.state.address.replace(/^(.{6}).+(.{4})$/, "$1...$2")}</div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

}

export default Connect;