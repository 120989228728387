import React from "react";

class TLoading extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: props.show
        }
    }

    render() {

        const loadingStyle = {
            display: this.props.show ? "block" : "none",
            position: "fixed",
            top: "50%",
            right: '0',
            left: "0",
            // transform: "translate(-50%, -50%)",
            zIndex: "9999",
            textAlign: "center",
            color: '#fff',
        }
        // console.log('aaaaaaaaaaaaaaaaaaaaaaa:', this.props.show)
        return (
            <div style={{
                position: "fixed",
                zIndex: "9998",
                top: "0",
                left: "0",
                bottom: "0",
                right: "0",
                backgroundColor: "rgba(0,0,0,0.4)",
                display: this.props.show ? "block" : "none",
            }}>
                <div style={loadingStyle}>
                    <div >
                        <span>Waiting...</span>
                    </div>
                </div>
            </div>
        )
    }
}
export default TLoading;