import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import IndexPageExport from './pages';
import TransactionResultPage from './pages/result';
import HelpPage from './pages/help';
import NewPage from './pages/new';
import CreatePersonalCollectPage from './pages/new/personal';

import PaymentPage from './pages/payment';
import NewMchPage from './pages/payment/newmch';
import CreatePaymentPage from './pages/payment/create';
import RealHumanPage from './pages/realhuman/step1';

import SignPage from './pages/sign';

import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<IndexPageExport />} />
      <Route path="/result" element={<TransactionResultPage />} />
      <Route path="/help" element={<HelpPage />} />
      <Route path="/new" element={<NewPage />} />
      <Route path="/new/personal" element={<CreatePersonalCollectPage />} />
      <Route path="/payment" element={<PaymentPage />} />
      <Route path="/payment/newmch" element={<NewMchPage />} />
      <Route path="/payment/create" element={<CreatePaymentPage />} />
      <Route path="/sign" element={<SignPage />} />
      <Route path="/verify" element={<RealHumanPage />} />
      
    </Routes>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
