import axios from 'axios';
// post to api from parameters
export const post = (url, params) => {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
};

// post json to api
export const postJson = (url, params) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            resolve(response.data);
        }
        ).catch(error => {
            reject(error);
        }
        );
    });
};

// get from api use axios   
export const get = (url, params) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: url,
            params: params
        }).then(response => {
            resolve(response.data);
        }
        ).catch(error => {
            reject(error);
        }
        );
    });
};
