export const CHAIN_CONFIG = [
    {
        "name": "Ethereum",
        "symbol": "ETH",
        "decimals": 18,
        "chainId": 1,
        "rpcUrls": [
            "https://ethereum.publicnode.com/"
        ],
        "explorer": "https://etherscan.io",
        "icon": "https://assets.trustwalletapp.com/blockchains/ethereum/info/logo.png?v=1597043857",
        "pollingInterval": 1,
        "payContract": null,
        "lockContract": null
    },
    {
        "name": "BSC",
        "symbol": "BNB",
        "decimals": 18,
        "chainId": 56,
        "rpcUrls": [
            "https://bsc-dataseed1.ninicoin.io/"
        ],
        "explorer": "https://bscscan.com",
        "icon": "https://assets.trustwalletapp.com/blockchains/smartchain/info/logo.png?v=1602812365",
        "pollingInterval": 1,
        "payContract": null,
        "lockContract": null
    },
    {
        "name": "Polygon",
        "symbol": "MATIC",
        "decimals": 18,
        "chainId": 137,
        "rpcUrls": ["https://rpc-mainnet.maticvigil.com"
        ],
        "explorer": "https://polygonscan.com",
        "pollingInterval": 1,
        "icon": "https://assets.trustwalletapp.com/blockchains/polygon/info/logo.png?v=1597043857",
        "payContract": null,
        "lockContract": null
        
    },
    {
        "name": "OKX",
        "symbol": "OKT",
        "decimals": 18,
        "chainId": 66,
        "rpcUrls": ["https://exchainrpc.okex.org"]
        ,
        "explorer": "https://www.oklink.com/okexchain",
        "pollingInterval": 1,
        "icon": "https://assets.trustwalletapp.com/blockchains/okexchain/info/logo.png?v=1597043857",
        "payContract": null,
        "lockContract": null
    },
    {
        "name": "Arbitrum",
        "symbol": "ARB",
        "decimals": 18,
        "chainId": 42161,
        "rpcUrls": ["https://arb1.arbitrum.io/rpc"]
        ,
        "explorer": "https://arbiscan.io",
        "pollingInterval": 1,
        "icon": "https://assets.trustwalletapp.com/blockchains/arbitrum/info/logo.png?v=1597043857",
        "payContract": null,
        "lockContract": null
    },
    {
        "name": "Linea",
        "symbol": "ETH",
        "decimals": 18,
        "chainId": 59144,
        "rpcUrls": ["https://linea.blockpi.network/v1/rpc/public"],

        "explorer": "https://lineascan.build",
        "pollingInterval": 1,
        "icon": "https://assets.trustwalletapp.com/blockchains/ethereum/info/logo.png?v=1597043857",
        "payContract": null,
        "lockContract": null
    },
    {
        "name": "Base",
        "symbol": "ETH",
        "decimals": 18,
        "chainId": 8453,
        "rpcUrls": ["https://developer-access-mainnet.base.org"]
        ,
        "explorer": "https://basescan.org",
        "pollingInterval": 1,
        "icon": "https://assets.trustwalletapp.com/blockchains/ethereum/info/logo.png?v=1597043857",
        "payContract": null,
        "lockContract": null
    },
    {
        "name": "BSC Testnet",
        "symbol": "tBNB",
        "decimals": 18,
        "chainId": 97,
        "rpcUrls": ["https://data-seed-prebsc-1-s1.binance.org:8545/"]
        ,
        "explorer": "https://testnet.bscscan.com",
        "icon": "https://assets.trustwalletapp.com/blockchains/smartchain/info/logo.png?v=1602812365",
        "pollingInterval": 1
        ,
        "payContract": '0x5Ba02110B62273CEdfE235AA175Fd933472EE3F5',
        'mchContract': '0xb99BC6BA5173c806CfeE053FAD693f26F8Cb0C01',
        'lockContract': '0x7448552b22f417B6cFD4E39403e2Ed0db0dACCAd'
    },
]

export function getChainById(chainId) {
    for (let i = 0; i < CHAIN_CONFIG.length; i++) {
        if (CHAIN_CONFIG[i].chainId == chainId) {
            return CHAIN_CONFIG[i];
        }
    }
    return null;
}