// 活体识别说明和支付页面，扫码支付后，等待后台返回结果，如果成功，打开活体识别页面
// 所有第三方调用这个页面，都把参数鞋带到URL里，参数为orderid=xxx&userid=xxxxxxx&callback=xxxxx&redirect=
// 该页面会把attachId传递给后台，后台会根据attachId查询出对应的订单，然后根据订单的状态，判断是否需要支付
// 如果需要支付，检查userid的账户余额并显示在界面上，让用户点击扣款，否则显示二维码，让用户自行扫码转账
// 系统扣款成功或者等待deposit入账扣款成功后，打开活体识别页面
// 用户识别完毕后， 将结果通过callback到指定URL，并使用sha3签名，签名的key为分配给第三方的接口密钥
// 如果携带redirect参数，前端也同时rediect，否则前端提示关闭按钮

import { Loader, ThemeProvider, useTheme } from '@aws-amplify/ui-react';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import React from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { createLivenessSession, getLivenessSessionState } from '../../api/liveness';
import { useSearchParams } from 'react-router-dom';
// import '@aws-amplify/ui/dist/style.css';
import '@aws-amplify/ui-react/styles.css';
// import { Amplify } from 'aws-amplify';   
// import awsexports from './aws-exports';

// Amplify.configure(awsexports);

class _RealHumanPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            id: null,
            callback: null,

            error: false,
            errorMessage: '',
            result: '',

            step: 0,
            steps: ['说明', '开始验证', '验证结果'],

            loading: true,
            createLivenessApiData: null,

            sessionId: null,
        };
    }

    componentDidMount() {

    }

    // _mountTelegram = () => {
    //     const script = document.createElement('script');
    //     script.async = true;
    //     script.src = 'https://telegram.org/js/telegram-widget.js?22';
    //     script.setAttribute('data-size', 'large');
    //     script.setAttribute('data-telegram-login', 'wrb_test_bot');
    //     script.setAttribute('data-request-access', 'write');
    //     window.onTelegramAuth = this.onTelegramAuth;
    //     script.setAttribute('data-onauth', 'window.onTelegramAuth(user)');
    //     // script.setAttribute('data-auth-url', this.redirectUrl);
    //     document.body.appendChild(script);
    // }

    // onTelegramAuth = (user) => {
    //     console.log('user', user);
    //     inWebLogin(user).then(res => {
    //         console.log('inWebLogin', res)
    //         if (res.code === 1) {
    //             const token = res.data
    //             setToken(token)
    //             this.setState({ isLogin: true });
    //         }
    //     })
    // }

    createSession = async () => {
        // loading 
        this.setState({ loading: true });

        const response = await createLivenessSession({
            id: this.props.id,
            callback: this.props.callback,
            timestamp: this.props.timestamp,
            nonce: this.props.nonce,
            src: this.props.src,
            sign: this.props.sign,
        })

        console.log('createSession', response)
        if (response.SessionId) {
            this.setState({ createLivenessApiData: response.data, loading: false, sessionId: response.SessionId });
        } else {
            this.setState({ error: true, errorMessage: response.msg, loading: false });
        }
    }

    checkResult = async () => {
        const res = await getLivenessSessionState(this.state.sessionId);
        console.log('checkResult', res);

        if (res.confidence) {
            const key = res.key;
            const callback = this.props.callback;
            if (callback && key) {
                if (callback.startsWith('https://t.me/')) {
                    window.location.href = `${callback}${key}`;
                } else {
                    window.location.href = `${callback}?key=${key}`;
                }
            }
        } else {
            // 没有通过
        }
    }

    next = () => {
        const { step } = this.state;
        if (step === this.state.steps.length - 1) {
            return;
        }
        this.setState({ step: step + 1 });
    }
    render() {
        const {tokens} = this.props;
        const theme = {
            name: 'Face Liveness Example Theme',
            tokens: {
                colors: {
                    background: {
                        primary: {
                            value: tokens.colors.neutral['90'].value,
                        },
                        secondary: {
                            value: tokens.colors.neutral['100'].value,
                        },
                    },
                    font: {
                        primary: {
                            value: tokens.colors.white.value,
                        },
                    },
                    brand: {
                        primary: {
                            '10': tokens.colors.teal['100'],
                            '80': tokens.colors.teal['40'],
                            '90': tokens.colors.teal['20'],
                            '100': tokens.colors.teal['10'],
                        },
                    },
                },
            },
        };
        const { step, loading } = this.state;
        return <div >

            {step === 0 ? <div style={{
                marginTop: '1.6rem'
            }}>
                <Container>
                    <Card>
                        <Card.Header>活体识别说明</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                1 您正在使用活体识别服务，该服务由第三方提供，本平台不对该服务的准确性和安全性负责<br />
                                2 点击下一步表示您同意使用识别，并开始识别<br />
                            </Card.Text>
                            <Button variant="primary" onClick={(e) => {
                                this.next();
                                this.createSession();
                            }}>下一步</Button>
                        </Card.Body>
                    </Card>
                </Container>
            </div> : null}
            {(step === 1 ? <div style={{
                width: '100%',
            }}>
                <ThemeProvider theme={theme}>
                    {loading && !this.state.sessionId ? <Loader /> : <FaceLivenessDetector
                        sessionId={this.state.sessionId}
                        reagion="us-east-1"
                        onAnalysisComplete={(result) => {
                            this.setState({ result });
                            this.checkResult();
                        }}
                        onError={(error) => {
                            this.setState({ error: true, errorMessage: error });
                        }}
                    />}
                </ThemeProvider>
            </div> : null)}
            {(step === 2 ? <div>验证结果</div> : null)}
        </div>
    }

}

function RealHumanPage() {
    // const hashParams = new URLSearchParams(window.location.hash)

    // if (hashParams) {
    //     const initDataRaw = hashParams.get('tgWebAppData')
    //     if (initDataRaw) {
    //         // 在TG里
    //         const initData = new URLSearchParams(initDataRaw);
    //         console.log('initData', initData)

    //         // 使用initData发送到服务器换取login token
    //         inAppLogin(initData).then(res => {
    //             console.log('inAppLogin', res)
    //             if (res.code === 1) {
    //                 const token = res.data
    //                 setToken(token)
    //                 window.location.hash = ''
    //                 document.location.reload()
    //             }
    //         })
    //     }
    // }

    const [params] = useSearchParams();

    const id = params.get('id');
    const callback = params.get('callback');
    const timestamp = params.get('timestamp');
    const nonce = params.get('nonce');
    const src = params.get('src');
    const sign = params.get('sign');


    const {tokens} = useTheme();
    
    return <_RealHumanPage
        id={id}
        callback={callback}
        timestamp={timestamp}
        nonce={nonce}
        src={src}
        sign={sign}
        tokens={tokens}
    />
}

export default RealHumanPage;