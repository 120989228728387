import React from 'react';

import { watchTxRecp, conn, getERC20Info } from '../api/dapp.js'
// import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import { getChainById } from '../api/constents.js';

import { useLocation } from 'react-router-dom';
import { ethers } from 'ethers';
import Bottom from '../components/common/Bottom.js';
import { useTranslation } from 'react-i18next';

class TransactionResult extends React.Component {

    constructor(props) {
        super(props);
        // props.txHash is Transaction hash
        this.state = {
            txStatus: 0, // 0 is process 1 is success 2 is failed
            tx: null,

            txFrom: null,
            txTo: null,
            txValue: null,
            txGas: null,
            txGasPrice: null,
            txNonce: null,
            txData: null
            ,
            txBlockNumber: null,

            loaded: false,
            chainId: null,

            symbol: null,
            amount: null,

            baseSymbol: '',
            re: null,

            browser: null,
            txValue: null,
        }

        this.timer = null
    }

    // mount
    componentDidMount() {
        this.timer = setInterval(() => {
            this.load()
        }, 20)
    }

    async load() {

        console.log("tx : " + this.state.tx)

        const res = await conn()
        const _this = this

        watchTxRecp(_this.timer, {
            provider: res.provider,
            txHash: _this.props.txHash,

            success: async (recp) => {
                console.log("recp : ", recp)

                const isContract = await _this._isContract(res, recp.to)
                console.log("isContract : ", isContract)

                let _txTo = recp.to

                // ether value
                // chain info
                const network = await res.provider.getNetwork()
                const chainId = network.chainId
                const chain = getChainById(chainId)

                console.log("chain : ", chain)

                _this.setState({
                    chainId: chainId,
                    baseSymbol: chain.symbol,
                    browser: chain.explorer

                })

                let _amount = (await recp.getTransaction()).value
                console.log("_amount : ", _amount)
                // ether
                if (_amount && _amount > 0) {
                    _this.setState({
                        symbol: chain.symbol,
                        amount: ethers.formatEther(_amount)
                    })
                } else if (isContract) {
                    // erco20, get the transfer events to find the receiver
                    const iface = new ethers.Interface([
                        "event Transfer(address indexed from, address indexed to, uint256 value)"
                    ]);

                    let _amount = null

                    const logs = recp.logs
                    let erc20contract = null;

                    for (let i = 0; i < logs.length; i++) {
                        const log = logs[i];
                        console.log("log : ", log)
                        erc20contract = log.address
                        const parsedLog = iface.parseLog(log);
                        console.log("parsedLog : ", parsedLog)

                        if (parsedLog && parsedLog.name === 'Transfer') {
                            _txTo = parsedLog.args.to
                            _amount = parsedLog.args.value
                            break
                        }
                    }

                    if (erc20contract) {
                        const erc20Info = await getERC20Info(res, erc20contract)
                        console.log("erc20Info : ", erc20Info)

                        _this.setState({
                            symbol: erc20Info.symbol,
                            amount: ethers.formatUnits(_amount, erc20Info.decimals)
                        })
                    } 
                    
                } 

                _this.setState({
                    txStatus: 1,
                    tx: recp,
                    txFrom: recp.from,
                    txTo: _txTo,
                    txValue: recp.value,
                    txGas: recp.gasUsed,
                    txGasPrice: recp.gasPrice,

                    txData: recp.data,
                    txBlockNumber: recp.blockNumber,
                    loaded: true,
                })
            },

            failed: (recp) => {
                _this.setState({
                    txStatus: 2,
                    tx: recp,
                    txFrom: recp.from,
                    txTo: recp.to,
                    txValue: recp.value,
                    txGas: recp.gasUsed,
                    txGasPrice: recp.gasPrice,
                    txData: recp.data,
                    txBlockNumber: recp.blockNumber,
                    loaded: true

                })
            },

            process: () => {
                _this.setState({
                    tx_status: 0
                })
            },

            error: (error) => {
                console.error(error)
                _this.setState({
                    tx_status: 2
                })
            }

        })
    }

    _isContract = async (res, address) => {
        const code = await res.provider.getCode(address)
        return code && code !== '0x'
    }

    render() {
        const { t, i18n } = this.props;

        const imgStyle = {
            width: '100px',
            height: '100px'
        }

        const rowStyle = {
            display: 'flex',
            justifyContent: 'space-between',
        }

        const rowKeyStyle = {
            // color: '#999999'
        }

        const rowValueStyle = {
            // color: '#333333'
        }

        const waitingIcon = <div>
            <img alt='' style={imgStyle} src={require('../assets/icon-wait.png')} />
            <div>Transaction is processing...</div>
        </div>

        const successIcon = <div>
            <img alt='' style={imgStyle} src={require('../assets/icon-success.png')} />
            <div>Transaction is success</div>
        </div>

        const failedIcon = <div>
            <img alt='' style={imgStyle} src={require('../assets/icon-fail.png')} />
            <div>Transaction is failed</div>
        </div>

        const boardStyle = {
            margin: '16px',
        }

        const displayA = <div style={rowStyle}>
            <div style={rowKeyStyle}>Token A: </div>
            <div style={rowValueStyle} >{this.state.amountA} {this.state.symbolA}</div>
        </div>

        const displayB = <div style={rowStyle}>
            <div style={rowKeyStyle}>Token B: </div>
            <div style={rowValueStyle} >{this.state.amountB} {this.state.symbolB}</div>
        </div>

        const cardStyle = {
            background: "rgba(255,255,255,0.01)",
            boxShadow: "inset 0px 0px 20px 0px rgba(255,255,255,0.2)",
            borderRadius: "12px",
            border: "1px solid rgba(255,255,255,0.21)",
            padding: "16px",
            // margin: "16px",
            lineHeight: '2',
        }

        const state = this.state
        return (
            <div>
                <div style={boardStyle}>

                    <center style={{ marginTop: '40px', marginBottom: '10px', lineHeight: '60px' }}>
                        {this.state.txStatus === 0 ? waitingIcon : this.state.txStatus === 1 ? successIcon : failedIcon}
                    </center>
                    <center>
                        {/* amount and symbol */}
                        <div style={{ fontSize: '24px', marginBottom: '10px' }}>
                            {state.amount} {state.symbol}
                        </div>
                    </center>

                    {/* <div style={{
                        backgroundColor: '#ffffff',
                        padding: '16px',
                        borderRadius: '8px',
                        boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.1)',
                        lineHeight: '2'
                    }}> */}
                    <div style={cardStyle}>
                        <div style={rowStyle}>
                            <div style={rowKeyStyle}>Hash: </div>
                            <div style={rowValueStyle} >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginRight: '6px' }}>{this.props.txHash.replace(/^(.{10}).+(.{8})$/, "$1...$2")}</div>

                                    <img onClick={(e) => {
                                        // copy the tx hash to clipboard
                                        navigator.clipboard.writeText(this.props.txHash)
                                    }} src={require('../assets/copy.png')} style={{ width: '20px', height: '20px' }}></img>
                                </div>
                            </div>
                        </div>

                        <div style={rowStyle}>
                            <div style={rowKeyStyle}>From: </div>
                            <div style={rowValueStyle} >{state.txFrom ? state.txFrom.replace(/^(.{6}).+(.{4})$/, "$1...$2") : 'Loading..'}</div>
                        </div>

                        <div style={rowStyle}>
                            <div style={rowKeyStyle}>To: </div>
                            <div style={rowValueStyle} >{state.txTo ? state.txTo.replace(/^(.{6}).+(.{4})$/, "$1...$2") : 'Loading...'}</div>
                        </div>

                        {/* {this.state.symbolA ? displayA : null}
                        {this.state.symbolB ? displayB : null} */}
                        {/* 
                    <div style={rowStyle}>
                        <div style={rowKeyStyle}>Transaction Value: </div>
                        <div style={rowValueStyle} >{(state.txValue && state.txValue > 0) ? ethers.formatEther(this.state.txValue) : 'Not applicable'}</div>
                    </div> */}

                        <div style={rowStyle}>
                            <div style={rowKeyStyle}>Gas used: </div>
                            <div style={rowValueStyle} >{state.txGas + ''}</div>
                        </div>

                        <div style={rowStyle}>
                            <div style={rowKeyStyle}>Gas Price: </div>
                            <div style={rowValueStyle} >{state.txGasPrice ? ethers.formatUnits(state.txGasPrice, 9) : 'Loading...'} gwei</div>
                        </div>

                        <div style={rowStyle}>
                            <div style={rowKeyStyle}>Gas cost: </div>
                            <div style={rowValueStyle} >{(state.txGasPrice && state.txGas) ? ethers.formatEther(this.state.txGasPrice) * (this.state.txGas + '') : 'Loading...'} {state.baseSymbol}</div>
                        </div>

                        <div style={rowStyle}>
                            <div style={rowKeyStyle}>Block Number: </div>
                            <div style={rowValueStyle} >{state.txBlockNumber ? state.txBlockNumber : 'Loading...'}</div>
                        </div>
                    </div>

                    {/** a button click to return /market */}
                    <div className="d-grid gap-2" style={{ padding: '1rem' }}>
                        <Button variant="success" size="lg" onClick={(e) => {
                            // if re is not null or empty, then return to the re, else close the window
                            if (this.state.re && this.state.re !== '') {
                                window.location.href = this.state.re
                            } else {
                                if (this.props.re && this.props.re !== '') {
                                    window.location.href = this.props.re
                                } else {
                                    window.close()
                                }
                            }
                        }}>
                            Return
                        </Button>
                    </div>

                    <div className="d-grid gap-2" style={{ padding: '1rem' }}>
                        <Button variant="link" size="lg" onClick={(e) => {
                            // open the tx hash in the browser
                            window.open(this.state.browser + '/tx/' + this.props.txHash, '_blank')
                            // window.location.href = this.state.browser + '/tx/' + this.props.txHash
                        }}>
                            {t('ResultPageViewOnScan')}
                        </Button>
                    </div>

                    <div className="d-grid gap-2" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                        <Button variant="link" size="lg" onClick={(e) => {
                            // open the tx hash in the browser
                            // window.open(this.state.browser + '/tx/' + this.props.txHash, '_blank')
                            // window.location.href = this.state.browser + '/tx/' + this.props.txHash
                            window.location.href = "/new";
                        }}>
                            {t('ResultPageCreateMyOwnTransferLink')}
                        </Button>
                    </div>
                </div>
                <div style={{
                    height: '5rem'
                }}>

                </div>
                <div style={{
                    position: 'fixed',
                    bottom: '0px',
                    width: '100%',

                }}>
                    <Bottom />
                </div>
            </div>
        );
    }
}

function TransactionResultPage() {
    let location = useLocation()
    console.log(location.state)

    const { t, i18n } = useTranslation();

    return (
        <div>
            <TransactionResult
                txHash={location.state.txHash}
                t={t}
                i18n={i18n}
                re={location.state.re}
            />
        </div>
    )
}

export default TransactionResultPage;