// 使用智能合约支付的落地页，这个可以携带订单ID，之后可以通过链上hash回调确认订单支付状态
import React from 'react';
import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { getChainById } from '../api/constents';
import { useNavigate } from "react-router-dom";
import { CHAIN_CONFIG } from '../api/constents';

import Connect from "../components/common/Connect.js";

import Header from "../components/common/Header.js";
import Bottom from '../components/common/Bottom.js';

import {
    connect,
    conn,
    getERC20Info,
    switchNetwork,
    getERC20Allowance,
    isInWeb3Browser,
    ABI_ERC20,
    watchTxRecp
} from "../api/dapp.js";

import { ethers } from "ethers";
import { Col, Container, Row } from 'react-bootstrap';
import Card from "react-bootstrap/Card";
import { Alert, Button } from "react-bootstrap";

import TLoading from '../components/common/TLoading.js';

// QRCode
import QRCode from 'qrcode.react';
// import copy
import copy from 'copy-to-clipboard';
import Deeplink from '../components/common/Deeplink.js';

const ABI = [
    'function pay(address _to, uint256 _mchId, uint256 _attachId) public payable',
    'function payERC20(address _token,address _to,uint256 _amount,uint256 _mchId,uint256 _attachId) public'
]

class _PaymentPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: null,
            chainName: 'Loading...',
            chainId: -1,
            loading: false,
            currency0: {
                symbol: '-',
                decimals: -1,
            },
            currency1: {
                symbol: '-',
                decimals: -1,
            },
            balance0: '-',
            balance1: '-',
            amount: 'Loading...',
            errorChain: false,
            chain: {},
            isInWeb3Browser: false,

            erc20allowance: null,
            timer: null
        };
    }

    componentDidMount() {
        this._init()
    }

    _init = async () => {
        const res = isInWeb3Browser();
        this.setState({
            isInWeb3Browser: res,
        });

        if (!res) {
            const decimals = this.props.params.decimals || 18;
            const symbol = this.props.params.symbol || 'Unknown';
            const amount = ethers.formatUnits(this.props.params.amount, decimals);
            this.setState({
                amount: amount,
                currency0: {
                    symbol: symbol,
                    decimals: decimals,
                },
                currency1: {
                    symbol: symbol,
                    decimals: decimals,
                },
            });
            return;
        }

        let options = await connect()
        this._checkChainId(options.chainId)

        const chain = getChainById(options.chainId)
        this.setState({
            chain: chain,
            chainName: chain.name,
            currency0: {
                symbol: chain.symbol,
                decimals: chain.decimals,
            },
        });

        this._initBalance(options)
    }


    _initBalance = async (res) => {

        if (!res.address) {
            console.log("address is null");
            const decimals = this.props.params.decimals || 18;
            // params amount
            const amount = ethers.formatUnits(this.props.params.amount, parseInt(decimals));
            // symbol1
            const symbol = this.props.params.symbol || 'Unknown';
            this.setState({
                amount: amount,
                currency1: {
                    symbol: symbol,
                    decimals: decimals,
                },
            });
            return;
        }

        let cid = res.chainId
        // 如果res.chainId是16进制

        // if ( === 'string' && res.chainId.startsWith('0x')) {
        //     cid = parseInt(res.chainId, 16);
        // }
        if (this.props.params.chainId != cid) {
            console.log("chainId not match");
            const decimals = this.props.params.decimals || 18;
            const symbol = this.props.params.symbol || 'Unknown';
            const amount = (this.props.params.amount && this.props.params.amount > 0) ? ethers.formatUnits(this.props.params.amount, decimals) : 0;
            this.setState({
                amount: amount,
                isInWeb3Browser: false,
                currency0: {
                    symbol: symbol,
                    decimals: decimals,
                },
                currency1: {
                    symbol: symbol,
                    decimals: decimals,
                },
            });
            return;
        }

        if (this.props.params.type == 1) {
            // ERC20
            const info = await getERC20Info(
                res.provider,
                this.props.params.token,
                res.address
            );

            const allowance = await getERC20Allowance(
                res.provider,
                this.props.params.token,
                res.address,
                this.state.chain.payContract
            );

            console.log("allowance = ", allowance);

            const amount = (this.props.params.amount && this.props.params.amount > 0) ? ethers.formatUnits(this.props.params.amount, info.decimals) : 0;

            this.setState({
                currency1: {
                    symbol: info.symbol,
                    decimals: info.decimals,

                },
                erc20allowance: allowance,
                amount: amount, //ethers.formatUnits(this.props.params.amount, info.decimals),
                balance1: ethers.formatUnits(info.balance, info.decimals),
            });
        } else {
            const amount = (this.props.params.amount && this.props.params.amount > 0) ? ethers.formatEther(this.props.params.amount) : 0;
            // eth
            this.setState({
                amount: amount, //ethers.formatEther(this.props.params.amount),
            });
        }

        // eth balance
        let balance = await res.provider.getBalance(res.address);

        this.setState({
            balance0: ethers.formatEther(balance),
        });

    }

    _checkChainId = async (chainId) => {

        this.setState({
            errorChain: this.props.params.chainId != chainId,
        });

        const that = this
        const { t } = this.props;

        if (this.props.params.chainId != chainId) {
            if (!window.confirm(t('IndexErrorNetwork'))) {
                return;
            }
            const targetChain = CHAIN_CONFIG.find((chain) => {
                return chain.chainId == this.props.params.chainId;
            });
            await switchNetwork(targetChain, () => {
                console.log("switch success");
                that._init();
            });
        }
    }

    doApprove = async () => {
        const props = this.props;
        const chainId = this.props.params.chainId
        const chain = getChainById(chainId)

        if (chain && chain.payContract) {
            // loading
            this.setState({
                loading: true,
            });

            try {
                const res = await connect()
                const provider = res.provider
                const signer = await provider.getSigner()

                const contract = new ethers.Contract(props.params.token, ABI_ERC20, signer)
                const tx = await contract.approve(chain.payContract, props.params.amount)

                const that = this
                watchTxRecp(this.state.timer, {
                    provider: provider,
                    txHash: tx.hash,
                    success: (recp) => {
                        that.setState({
                            loading: false,
                        });
                        that._init()
                    },
                    failed: () => {
                        that.setState({
                            loading: false,
                        });
                    },
                    error: () => {
                        that.setState({
                            loading: false,
                        });

                    }
                })
            } catch (e) {
                console.log(e);
                alert(e.reason);
                this.setState({
                    loading: false,
                });
            }

        } else {
            // console
            console.log("chain not support pay")
        }
    }

    doPay = async () => {
        const props = this.props;
        const chainId = this.props.params.chainId
        const chain = getChainById(chainId)

        if (chain && chain.payContract) {

            const res = await connect()
            const provider = res.provider
            const signer = await provider.getSigner()

            const contract = new ethers.Contract(chain.payContract, ABI, signer)

            try {
                if (props.params.type === '0') {
                    const tx = await contract.pay(props.params.to,
                        props.params.mchId,
                        props.params.attachId, {
                        value: props.params.amount
                    })
                    props.onTx(tx)
                } else if (props.params.type === '1') {
                    const tx = await contract.payERC20(props.params.token,
                        props.params.to,
                        props.params.amount,
                        props.params.mchId,
                        props.params.attachId)
                    props.onTx(tx)
                }
            } catch (e) {
                console.log(JSON.stringify(e));
                alert(e.reason);
            }

        } else {
            // console
            console.log("chain not support pay")
        }
    }

    _onAccountChange = async (res) => {
        this._initBalance(res)
    }

    _onChainSwitch = async (res) => {
        const chain = getChainById(res.chainId)
        this.setState({
            chain: chain,
        });
        this._checkChainId(res.chainId)
    }

    _onConnect = async (res) => {
        this._initBalance(res)
        // this._init()
    }

    render() {
        const state = this.state;
        const props = this.props;
        const t = props.t;

        return (
            <div style={{
            }}  >
                <Header />
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // minHeight: "100vh",
                    backgroundColor: "#f5f5f5",
                }}>
                    <div style={{
                        padding: "0.5rem",
                        maxWidth: "600px",
                    }}>
                        <Container >
                            <Row >
                                <Col><div style={{ height: '1rem' }} ></div>
                                    {state.isInWeb3Browser ? <div> <Card>
                                        <Card.Body>
                                            <div>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",

                                                }}>
                                                    <div style={{
                                                        color: state.errorChain ? 'red' : "green",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }} >
                                                        <img src={state.chain.icon} style={{
                                                            width: '1.8rem',
                                                            height: '1.8rem',
                                                        }}></img><div style={{ marginLeft: '0.5rem' }}>{this.state.chainName}</div>
                                                    </div>
                                                    <div>
                                                        <Connect onAccountChange={this._onAccountChange}
                                                            onChainSwitch={this._onChainSwitch}
                                                            onConnect={this._onConnect}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ height: '1rem' }}>

                                                </div>
                                                <div>
                                                    {state.balance0} {state.currency0.symbol}
                                                </div>
                                                {/* if params.type===1 then show  */}
                                                {(props.params.type == 1) ? <div>

                                                    <div>
                                                        {state.balance1} {state.currency1.symbol}
                                                    </div>
                                                    {(state.erc20allowance != null && props.params.amount > state.erc20allowance) ? <div>
                                                        {/* <Alert variant="danger">
                                                        {t('PaymentPageAllowanceNotEnough')}
                                                    </Alert> */}
                                                    </div> : null}
                                                </div> : null}
                                            </div>
                                        </Card.Body>
                                    </Card> </div> : null}
                                </Col>
                            </Row>
                            <div style={{ height: '1rem' }}></div>
                            <Row>
                                <Col>
                                    {/* 支付信息 */}
                                    <Card>
                                        <Card.Header>
                                            Order ID : {props.params.attachId}
                                        </Card.Header>
                                        <Card.Body>

                                            <div style={{
                                                'display': 'flex',
                                                'justifyContent': 'space-between',
                                                'alignItems': 'start',
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "start",
                                                }}>
                                                    {(props.params.logo) ? <div>
                                                        <img src={props.params.logo} style={{
                                                            width: '4rem',
                                                            height: '4rem',
                                                        }}></img>
                                                    </div> : null}
                                                    <div style={{
                                                        marginLeft: '1rem',
                                                        wordBreak: 'break-all',
                                                    }}>
                                                        <div>
                                                            {props.params.title}
                                                        </div>
                                                        <div>
                                                            {props.params.desc}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '1rem',
                                                    marginLeft: '0.3rem',
                                                    textAlign: 'center',
                                                }}>
                                                    <div>{state.amount}</div>
                                                    <div>{props.params.type === '1' ? state.currency1.symbol : state.currency0.symbol}</div>
                                                </div>

                                            </div>

                                        </Card.Body>
                                        {/* MchID */}
                                        <Card.Footer>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",

                                            }}>
                                                <div>MchID : {props.params.mchId}</div>
                                                <div style={{
                                                    color: 'blue',
                                                }} onClick={(e) => {
                                                    this.setState({
                                                        showQRCode: true,
                                                    });
                                                }}>
                                                    {t('PaymentPageQRCode')}
                                                </div>
                                            </div>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            </Row>
                            <div style={{ height: '1rem' }}></div>
                            <Row>
                                <Col>
                                    <Alert variant="info">
                                        <b>Payment Contract: </b>
                                        <div style={{
                                            wordBreak: 'break-all',

                                        }}>
                                            {state.chain.payContract}
                                        </div>
                                        <div style={{ color: 'blue' }} onClick={
                                            (e) => {
                                                const url = state.chain.explorer + '/address/' + state.chain.payContract;
                                                window.open(url);
                                            }
                                        }>
                                            {t('ResultPageViewOnScan')}
                                        </div>
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {/* 支付按钮 */}
                                    {state.isInWeb3Browser ? <div className="d-grid gap-2" >
                                        {props.params.type === '1' ? <div>
                                            {t('PaymentPageAllowanceInfo')}: <span style={{
                                                color: (state.erc20allowance != null && props.params.amount > state.erc20allowance) ? 'red' : 'green',
                                            }}>{state.erc20allowance !== null ? ethers.formatUnits(state.erc20allowance, state.currency1.decimals) : '-'}
                                                &nbsp;{state.currency1.symbol}</span>
                                        </div> : null}
                                        {(props.params.type === '1' && state.erc20allowance != null && props.params.amount > state.erc20allowance) ? <div>
                                            <Alert variant="danger">
                                                {t('PaymentPageAllowanceNotEnough')}
                                            </Alert>

                                        </div> : null}

                                        {(props.params.type === '1' && state.erc20allowance != null && props.params.amount > state.erc20allowance) ?
                                            <Button variant="primary" onClick={(e) => {
                                                // do approve
                                                this.doApprove()
                                            }} size="lg">
                                                {t('PaymentPageDoApprove', { symbol: state.currency1.symbol, amount: ethers.formatUnits(props.params.amount, state.currency1.decimals) })}
                                            </Button> :
                                            <Button variant="primary" onClick={(e) => {
                                                this.doPay();
                                            }} size="lg">
                                                {t('PaymentPagePayButton', { symbol: props.type === '1' ? state.currency1.symbol : state.currency0.symbol, amount: state.amount })}

                                            </Button>}

                                    </div> :
                                        <div style={{ color: 'red', marginTop: '1rem' }}>
                                            <Alert variant="danger">
                                                {t('IndexNotSupportLine1')}
                                                <div>
                                                    {t('IndexNotSupportLine2')}
                                                </div>
                                                <div>
                                                    {t('IndexNotSupportLine3')}
                                                </div>
                                            </Alert>
                                        </div>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={{ marginTop: '1rem' }}>
                                        {t('IndexYouCanAlso')}
                                    </div>
                                </Col>
                            </Row>
                            {/* okx wallet */}
                            <Deeplink />
                            <Row>
                                <Col>
                                    {t('IndexYouCanAlsoToo')}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="d-grid gap-2" style={{ marginTop: '0.6rem' }}>
                                        <Button variant="light" size="lg" onClick={(e) => {
                                            copy(window.location.href);
                                            alert(t('IndexCopySuccess'));
                                        }}>
                                            {t('IndexCopyThisPage')}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="d-grid gap-2" style={{ marginTop: '0.6rem' }}>
                                        <Button variant="link" size="lg" onClick={(e) => {
                                            // a href = /new
                                            window.location.href = '/payment/newmch';
                                        }}>
                                            How to integrate this page?
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    </div>
                </div>
                <Bottom />
                <TLoading show={this.state.loading} />
                <div style={{
                    // mask
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    zIndex: '100',
                    display: this.state.showQRCode ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                }} onClick={(e) => {
                    this.setState({
                        showQRCode: false,
                    });
                }}>
                    <div style={{
                        backgroundColor: '#ffffff',
                        padding: '1rem',
                        borderRadius: '0.5rem',
                        width: '80%',
                        // center
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',

                    }}>
                        <QRCode value={window.location.href}
                            size={256} fgColor="#000000" bgColor="#ffffff"
                        />
                        <div style={{
                            wordBreak: 'break-all',
                            textAlign: 'center',
                            // width: '100%',
                            // marginTop: '1rem',
                            // marginLeft: '1rem',
                        }}>
                            <div>Order ID: {props.params.attachId}</div>
                            <div>Amount: <b>{state.amount}</b> {props.params.type == 1 ? state.currency1.symbol :
                                state.currency0.symbol
                            }</div>
                            <div>Mch ID:
                                {props.params.mchId}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

function PaymentPage() {
    const [params] = useSearchParams();
    const { t, i18n } = useTranslation();
    const p = {
        chainId: params.get("chainId"),
        token: params.get("token"),
        amount: params.get("amount"),
        attachId: params.get("attachId"),
        re: params.get("re"),
        title: params.get("title"),
        desc: params.get("desc"),
        logo: params.get("logo"),
        type: params.get("type"),
        mchId: params.get("mchId"),
        symbol: params.get("symbol"),
        decimals: params.get("decimals"),
        to: params.get("to"),
    }

    const navigate = useNavigate();

    return (
        <_PaymentPage params={p}
            onTx={(tx) => {
                navigate('/result', {
                    state: {
                        txHash: tx.hash,
                        chainId: params.get('chainId'),
                        re: params.get('re'),
                    }
                });
            }
            } t={t} i18n={i18n}
        />
    )
}

export default PaymentPage;