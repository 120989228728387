import React, { useTransition } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
// i18n.js
import i18n from '../../i18n';

class _Deeplink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
        };
    }

    render() {
        const { t, i18n } = this.props;

        return (
            <div
                style={{
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '1rem',
                    fontSize: '1.4rem',
                    lineHeight: '2.6rem',
                }}
            >
                <Row>
                    <Col>
                        <div className="d-grid gap-2" style={{ marginTop: '0.6rem' }}>
                            <a href={'okx://wallet/dapp/details?dappUrl=' + encodeURIComponent(window.location.href)}>
                                {t('IndexOpenWithOKXWallet')}
                            </a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="d-grid gap-2" style={{ marginTop: '0.6rem' }}>
                            <a href={'https://metamask.app.link/dapp/' + window.location.href}>
                                {t('IndexOpenWithMetamask')}
                            </a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="d-grid gap-2" style={{ marginTop: '0.6rem' }}>
                            <a href={'imtokenv2://navigate/DappView?url=' + encodeURIComponent(window.location.href)}>
                                {t('IndexOpenWithImToken')}
                            </a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="d-grid gap-2" style={{ marginTop: '0.6rem' }}>
                            {/* 
                                        https://go.cb-w.com/dapp?cb_url=[YOUR_URL]
                                        YOUR_URL should be encoded with percent-encoding.

                                        iOS-specific information
                                        You may use a custom protocol instead of the universal link. This is helpful if users are running into the iOS bug that routes users to the App Store instead of directly to the app when it's already installed.

                                        cbwallet://dapp?url=[YOUR_URL]
                                    */}
                            <a href={navigator.userAgent.indexOf('iPhone') > -1 ? 'cbwallet://dapp?url=' + encodeURIComponent(window.location.href)
                                : 'https://go.cb-w.com/dapp?cb_url=' + encodeURIComponent(window.location.href)}>
                                {t('IndexOpenWithCoinbase')}
                            </a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="d-grid gap-2" style={{ marginTop: '0.6rem' }}>
                            {/* 
                                        <a href='tpdapp://open?params={}'>Open TokenPocket to open url</a><br/>
                                        {
                                            "url": "https://dapp.mytokenpocket.vip/referendum/index.html#/",
                                            "chain": "EOS",
                                            "source": "xxx" 
                                        }
                                    */}

                            <a href={'tpdapp://open?params=' + encodeURIComponent(JSON.stringify({
                                "url": window.location.href,
                                // "chain": state.chain.name,
                                "source": "ING"
                            }))}>
                                {t('IndexOpenWithTokenPocket')}
                            </a>
                        </div>
                    </Col>
                </Row>

            </div>
        );
    }
}

function Deeplink() {
    const { t, i18n } = useTranslation();

    return (
        <_Deeplink t={t} i18n={i18n} />
    );
}

export default Deeplink;