import React from "react";

class Bottom extends React.Component {

    render() {
        return (
            <div style={{
                backgroundColor: "#000000",
                padding: "1rem",
                color: "#eeeeee",
                textAlign: "center",
                // marginTop: "1rem",
                minHeight: "5rem",
            }}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                }}>
                    <div>Document</div>
                    <div>Terms</div>
                </div>
            </div>
        )
    };

}

export default Bottom;