import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // 检测用户当前使用的语言
    // 文档: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // 注入 react-i18next 实例
    .use(initReactI18next)
    // 初始化 i18next
    // 配置参数的文档: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    "AppName": "Transfer",
                    "IndexTitle": "You are paying",
                    "IndexAmountInputPlaceholder": "Please enter the amount",
                    "IndexCopySuccess": "Copy success",
                    "IndexCopyAddress": "Copy address",
                    "IndexTo": "Transfer to",
                    "IndexQRCode": "QR Code",
                    "IndexThisAddressIsMarkedAs": "This address is marked as:",
                    "IndexThisAddressIsMarkedAsNone": "None",
                    "IndexTransactionMemo": "Transaction memo:",
                    "IndexNotSupportLine1": "Not support direct payment, maybe:",
                    "IndexNotSupportLine2": "1. You don't install wallet or open in DAPP browser",
                    "IndexNotSupportLine3": "2. Your wallet doesn't support current chain",
                    "IndexYouCanAlso": "You can also",
                    "IndexOpenWithOKXWallet": "Open with OKX Wallet",
                    "IndexOpenWithMetamask": "Open with Metamask",
                    "IndexOpenWithImToken": "Open with imToken",
                    "IndexOpenWithTokenPocket": "Open with TokenPocket",
                    "IndexOpenWithCoinbase": "Open with Coinbase",
                    "IndexYouCanAlsoToo": "You can also",
                    "IndexCopyThisPage": "Copy this page link to others",
                    "IndexCreateMyPage": "Generate my payment link",

                    "IndexInvalidAmount": "Please enter the correct amount",
                    "IndexTransactionFailed": "Transaction failed",
                    "IndexErrorNetwork": "The current network is inconsistent with the target network, whether to switch the network?",

                    "NewTitle": "Privacy and Security",
                    "NewLine1": "This application only provides the display of payment information and wallet invocation functions.",
                    "NewLine2": "This application does not interact with the server side, does not collect any user information, and does not save any user information.",
                    "NewLine3": "This application is not responsible for verifying the authenticity of the payment information, nor is it responsible for verifying the legality of the payment information.",
                    "NewLine4": "This application does not charge any transaction fees, and the transaction funds will directly enter the receiving account.",
                    "NewLine5": "Using this application means that you accept the above terms.",

                    "NewPersonal": "Personal Payment",
                    "NewPersonalLine1": "Personal payment means sending a payment link to the payer",
                    "NewPersonalLine2": "The payer can complete the payment by scanning the QR code, copying the payment address and transferring money",
                    "NewPersonalLine3": "The payer can also complete the payment by opening the third-party wallet",
                    "NewPersonalButton": "Generate my payment link",

                    "NewOrder": "Order Payment",

                    "PersonalSelectChain": "Select chain",
                    "PersonalSelectTokenType": "Select currency type",
                    "PersonalContractAddress": "ERC20/BIP20 Contract Address",
                    "PersonalContractAddressPlaceholder": "Please enter contract address",
                    "PersonalTo": "To",
                    "PersonalToPlaceholder": "Please enter to address",
                    "PersonalAmount": "Amount",
                    "PersonalAmountPlaceholder": "Please enter amount",
                    "PersonalMemo": "Memo",
                    "PersonalMemoPlaceholder": "Please enter memo",
                    "PersonalGenerateLink": "Generate payment link",
                    "PersonalGenerateLinkSuccess": "Payment link generated successfully",
                    "PersonalCopyLink": "Copy payment link",
                    "PersonalCopyLinkSuccess": "Payment link copied successfully",
                    "PersonalClose": "Close",

                    "ButtonPay": "Transfer",
                    "ResultPageViewOnScan": "View on blockchain explorer",
                    "ResultPageCreateMyOwnTransferLink": "I also want to create a payment page",

                    "PaymentPagePayButton": "Pay {{amount}} {{symbol}}",
                    "PaymentPageQRCode": "QR Code",

                    "PaymentPageAllowanceInfo": "Allowance",
                    "PaymentPageAllowanceNotEnough": "Allowance to payment contract not enough",
                    "PaymentPageDoApprove": "Approve {{amount}} {{symbol}}",

                }
            },
            id: {
                // 印尼语翻译
                translation: {
                    // AppName: Transfer
                    "AppName": "Transfer",
                    "IndexTitle": "Anda sedang membayar",
                    "IndexAmountInputPlaceholder": "Silakan masukkan jumlah",
                    "IndexCopySuccess": "Salin berhasil",
                    "IndexCopyAddress": "Salin alamat",
                    "IndexTo": "Bayar ke",
                    "IndexQRCode": "Kode QR",
                    "IndexThisAddressIsMarkedAs": "Alamat ini ditandai sebagai:",
                    "IndexThisAddressIsMarkedAsNone": "Tidak ada",
                    "IndexTransactionMemo": "Memo transaksi:",
                    "IndexNotSupportLine1": "Tidak mendukung pembayaran langsung, mungkin:",
                    "IndexNotSupportLine2": "1. Anda tidak menginstal dompet atau membuka di browser DAPP",
                    "IndexNotSupportLine3": "2. Dompet Anda tidak mendukung rantai saat ini",
                    "IndexYouCanAlso": "Anda juga bisa",
                    "IndexOpenWithOKXWallet": "Buka dengan OKX Wallet",
                    "IndexOpenWithMetamask": "Buka dengan Metamask",
                    "IndexOpenWithImToken": "Buka dengan imToken",
                    "IndexOpenWithTokenPocket": "Buka dengan TokenPocket",
                    "IndexOpenWithCoinbase": "Buka dengan Coinbase",
                    "IndexYouCanAlsoToo": "Anda juga bisa",
                    "IndexCopyThisPage": "Salin tautan halaman ini ke orang lain",
                    "IndexCreateMyPage": "Saya ingin menerima pembayaran",

                    "IndexInvalidAmount": "Silakan masukkan jumlah yang benar",
                    "IndexTransactionFailed": "Transaksi gagal",
                    "IndexErrorNetwork": "Jaringan saat ini tidak konsisten dengan jaringan target, apakah akan beralih ke jaringan?",

                    "NewTitle": "Privasi dan Keamanan",
                    "NewLine1": "Aplikasi ini hanya menyediakan tampilan informasi pembayaran dan fungsi pemanggilan dompet.",
                    "NewLine2": "Aplikasi ini tidak berinteraksi dengan sisi server, tidak mengumpulkan informasi pengguna apa pun, dan tidak menyimpan informasi pengguna apa pun.",
                    "NewLine3": "Aplikasi ini tidak bertanggung jawab untuk memverifikasi keaslian informasi pembayaran, juga tidak bertanggung jawab untuk memverifikasi legalitas informasi pembayaran.",
                    "NewLine4": "Aplikasi ini tidak mengenakan biaya transaksi apa pun, dan dana transaksi akan langsung masuk ke akun penerima.",
                    "NewLine5": "Menggunakan aplikasi ini berarti Anda menerima persyaratan di atas.",

                    "NewPersonal": "Pembayaran Pribadi",
                    "NewPersonalLine1": "Pembayaran pribadi berarti mengirimkan tautan pembayaran ke pembayar",
                    "NewPersonalLine2": "Pembayar dapat menyelesaikan pembayaran dengan memindai kode QR, menyalin alamat pembayaran dan mentransfer uang",
                    "NewPersonalLine3": "Pembayar juga dapat menyelesaikan pembayaran dengan membuka dompet pihak ketiga",
                    "NewPersonalButton": "Buat tautan pembayaran saya",

                    "NewOrder": "Pembayaran Pesanan",

                    "PersonalSelectChain": "Pilih rantai",
                    "PersonalSelectTokenType": "Pilih jenis mata uang",
                    "PersonalContractAddress": "Alamat Kontrak ERC20/BIP20",
                    "PersonalContractAddressPlaceholder": "Silakan masukkan alamat kontrak",
                    "PersonalTo": "Untuk",
                    "PersonalToPlaceholder": "Silakan masukkan alamat",
                    "PersonalAmount": "Jumlah",
                    "PersonalAmountPlaceholder": "Silakan masukkan jumlah",
                    "PersonalMemo": "Memo",
                    "PersonalMemoPlaceholder": "Silakan masukkan memo",
                    "PersonalGenerateLink": "Buat tautan pembayaran",
                    "PersonalGenerateLinkSuccess": "Tautan pembayaran berhasil dibuat",
                    "PersonalCopyLink": "Salin tautan pembayaran",
                    "PersonalCopyLinkSuccess": "Tautan pembayaran berhasil disalin",
                    "PersonalClose": "Tutup",

                    "ButtonPay": "Bayar",
                    "ResultPageViewOnScan": "Lihat di penjelajah blockchain",
                    "ResultPageCreateMyOwnTransferLink": "Saya juga ingin membuat halaman pembayaran",

                    "PaymentPagePayButton": "Bayar Sekarang {{amount}} {{symbol}}",
                    "PaymentPageQRCode": "Kode QR",

                    "PaymentPageAllowanceInfo": "Izin",
                    "PaymentPageAllowanceNotEnough": "Izin ke kontrak pembayaran tidak cukup",
                    "PaymentPageDoApprove": "Setujui {{amount}} {{symbol}}",
                }
            },
            cn: {
                translation: {
                    "AppName": "转账",
                    "IndexTitle": "你正在转账",
                    "IndexAmountInputPlaceholder": "请输入金额",
                    "IndexCopySuccess": "复制成功",
                    "IndexCopyAddress": "复制地址",
                    "IndexTo": "转账给",
                    "IndexQRCode": "二维码",
                    "IndexThisAddressIsMarkedAs": "该地址被标记为：",
                    "IndexThisAddressIsMarkedAsNone": "暂无标记",
                    "IndexTransactionMemo": "交易备注：",
                    "IndexNotSupportLine1": "暂不支持直接转账,可能原因：",
                    "IndexNotSupportLine2": "1. 您未安装钱包或者在DAPP浏览器中打开",
                    "IndexNotSupportLine3": "2. 您的钱包不支持当前链",
                    "IndexYouCanAlso": "您也可以",
                    "IndexOpenWithOKXWallet": "使用OKX钱包打开",
                    "IndexOpenWithMetamask": "使用Metamask打开",
                    "IndexOpenWithImToken": "使用imToken打开",
                    "IndexOpenWithTokenPocket": "使用TokenPocket打开",
                    "IndexOpenWithCoinbase": "使用Coinbase打开",
                    "IndexYouCanAlsoToo": "您也可以",
                    "IndexCopyThisPage": "复制页面链接请他人代付",
                    "IndexCreateMyPage": "我也要收款",

                    "IndexInvalidAmount": "请输入正确的金额",
                    "IndexTransactionFailed": "交易失败",
                    "IndexErrorNetwork": "当前网络与目标网络不一致，是否切换网络？",


                    "NewTitle": "隐私和安全",
                    "NewLine1": "本应用仅提供收款信息的展示和钱包唤起功能。",
                    "NewLine2": "本应用不与服务器端有任何数据交互，不会收集任何用户信息，也不会保存任何用户信息。",
                    "NewLine3": "本应用不负责核实收款信息的真实性，也不负责核实收款信息的合法性。",
                    "NewLine4": "本应用不收取任何交易费用，交易资金会直接进入收方账号。",
                    "NewLine5": "使用本应用意味着您接受以上条款。",

                    "NewPersonal": "个人收款",
                    "NewPersonalLine1": "个人收款是指向付款方发出收款链接",
                    "NewPersonalLine2": "付款方可以通过扫描二维码、复制收款地址并进行转账来完成转账",
                    "NewPersonalLine3": "付款方还可以通过打开第三方钱包的方式来完成转账",
                    "NewPersonalButton": "生成我的收款链接",

                    "NewOrder": "订单收款",

                    "PersonalSelectChain": "选择收款链",
                    "PersonalSelectTokenType": "选择收取币种",
                    "PersonalContractAddress": "ERC20/BIP20 合约地址",
                    "PersonalContractAddressPlaceholder": "请输入合约地址",
                    "PersonalTo": "收款地址",
                    "PersonalToPlaceholder": "请输入收款地址",
                    "PersonalAmount": "收款金额",
                    "PersonalAmountPlaceholder": "请输入收款金额",
                    "PersonalMemo": "备注",
                    "PersonalMemoPlaceholder": "请输入备注",
                    "PersonalGenerateLink": "生成收款链接",
                    "PersonalGenerateLinkSuccess": "收款链接生成成功",
                    "PersonalCopyLink": "复制收款链接",
                    "PersonalCopyLinkSuccess": "收款链接复制成功",
                    "PersonalClose": "关闭",

                    "ButtonPay": "转账",

                    "ResultPageViewOnScan": "在区块链浏览器上查看",
                    "ResultPageCreateMyOwnTransferLink": "我也要创建收款页面",
                    
                    "PaymentPagePayButton": "支付 {{amount}} {{symbol}}",
                    "PaymentPageQRCode": "二维码",

                    "PaymentPageAllowanceInfo": "已授权",
                    "PaymentPageAllowanceNotEnough": "对支付合约的授权额度不足",
                    "PaymentPageDoApprove": "授权 {{amount}} {{symbol}}",
                }
            },
            // 韩语
            ko: {
                translation: {
                    "AppName": "송금",
                    "IndexTitle": "당신은 지불하고 있습니다",
                    "IndexAmountInputPlaceholder": "금액을 입력하십시오",
                    "IndexCopySuccess": "복사 성공",
                    "IndexCopyAddress": "주소 복사",
                    "IndexTo": "에 지불",
                    "IndexQRCode": "QR 코드",
                    "IndexThisAddressIsMarkedAs": "이 주소는 다음과 같이 표시됩니다.",
                    "IndexThisAddressIsMarkedAsNone": "없음",
                    "IndexTransactionMemo": "거래 메모 :",
                    "IndexNotSupportLine1": "직접 지불을 지원하지 않습니다. 아마도 :",
                    "IndexNotSupportLine2": "1. 지갑을 설치하지 않았거나 DAPP 브라우저에서 열려 있습니다.",
                    "IndexNotSupportLine3": "2. 지갑이 현재 체인을 지원하지 않습니다.",
                    "IndexYouCanAlso": "당신도 할 수 있습니다",
                    "IndexOpenWithOKXWallet": "OKX 지갑으로 열기",
                    "IndexOpenWithMetamask": "Metamask로 열기",
                    "IndexOpenWithImToken": "imToken으로 열기",
                    "IndexOpenWithTokenPocket": "TokenPocket으로 열기",
                    "IndexOpenWithCoinbase": "Coinbase로 열기",
                    "IndexYouCanAlsoToo": "당신도 할 수 있습니다",
                    "IndexCopyThisPage": "이 페이지 링크를 복사하여 다른 사람에게 보냅니다",
                    "IndexCreateMyPage": "나도 받고 싶어요",

                    "IndexInvalidAmount": "정확한 금액을 입력하십시오",
                    "IndexTransactionFailed": "거래 실패",
                    "IndexErrorNetwork": "현재 네트워크가 대상 네트워크와 일치하지 않습니다. 네트워크를 전환 하시겠습니까?",

                    "NewTitle": "개인 정보 및 보안",
                    "NewLine1": "이 응용 프로그램은 지불 정보의 표시 및 지갑 호출 기능 만 제공합니다.",
                    "NewLine2": "이 응용 프로그램은 서버 측과 상호 작용하지않으며 사용자 정보를 수집하지 않으며 사용자 정보를 저장하지 않습니다.",
                    "NewLine3": "이 응용 프로그램은 지불 정보의 진실성을 검증 할 책임이 없으며 지불 정보의 합법성을 검증 할 책임이 없습니다.",
                    "NewLine4": "이 응용 프로그램은 수수료를 부과하지 않습니다. 거래 자금은 직접 수신 계정으로 입금됩니다.",
                    "NewLine5": "이 응용 프로그램을 사용하면 위의 조건에 동의하는 것으로 간주됩니다.",

                    "NewPersonal": "개인 지불",
                    "NewPersonalLine1": "개인 지불은 지불 링크를 지불자에게 보내는 것을 의미합니다.",
                    "NewPersonalLine2": "지불자는 QR 코드를 스캔하거나 지불 주소를 복사하거나 송금하여 지불을 완료 할 수 있습니다.",
                    "NewPersonalLine3": "지불자는 타사 지갑을 열어 지불을 완료 할 수도 있습니다.",
                    "NewPersonalButton": "지불 링크 생성",

                    "NewOrder": "주문 지불",

                    "PersonalSelectChain": "체인 선택",
                    "PersonalSelectTokenType": "통화 유형 선택",
                    "PersonalContractAddress": "ERC20/BIP20 계약 주소",
                    "PersonalContractAddressPlaceholder": "계약 주소를 입력하십시오",
                    "PersonalTo": "에",
                    "PersonalToPlaceholder": "지불 주소를 입력하십시오",
                    "PersonalAmount": "양",
                    "PersonalAmountPlaceholder": "금액을 입력하십시오",
                    "PersonalMemo": "메모",
                    "PersonalMemoPlaceholder": "메모를 입력하십시오",
                    "PersonalGenerateLink": "지불 링크 생성",
                    "PersonalGenerateLinkSuccess": "지불 링크 생성 성공",
                    "PersonalCopyLink": "지불 링크 복사",
                    "PersonalCopyLinkSuccess": "지불 링크 복사 성공",
                    "PersonalClose": "닫기",

                    "ButtonPay": "지불",
                    "ResultPageViewOnScan": "블록 체인 탐색기에서보기",
                    "ResultPageCreateMyOwnTransferLink": "나도 지불 페이지를 만들고 싶습니다",

                    "PaymentPagePayButton": "지금 지불하기 {{amount}} {{symbol}}",
                    "PaymentPageQRCode": "QR 코드",

                    "PaymentPageAllowanceInfo": "허용",
                    "PaymentPageAllowanceNotEnough": "지불 계약에 대한 허용 부족",
                    "PaymentPageDoApprove": "{{amount}} {{symbol}} 승인",
                }

            },

            // 日语
            ja: {
                translation: {
                    "AppName": "送金",
                    "IndexTitle": "あなたは支払っています",
                    "IndexAmountInputPlaceholder": "金額を入力してください",
                    "IndexCopySuccess": "コピーに成功しました",
                    "IndexCopyAddress": "アドレスをコピー",
                    "IndexTo": "に支払う",
                    "IndexQRCode": "QRコード",
                    "IndexThisAddressIsMarkedAs": "このアドレスは次のようにマークされています：",
                    "IndexThisAddressIsMarkedAsNone": "なし",
                    "IndexTransactionMemo": "トランザクションメモ：",
                    "IndexNotSupportLine1": "直接支払いはサポートされていません、おそらく：",
                    "IndexNotSupportLine2": "1. ウォレットがインストールされていないか、DAPPブラウザで開かれている",
                    "IndexNotSupportLine3": "2. あなたのウォレットは現在のチェーンをサポートしていません",
                    "IndexYouCanAlso": "あなたもできます",
                    "IndexOpenWithOKXWallet": "OKXウォレットで開く",
                    "IndexOpenWithMetamask": "Metamaskで開く",
                    "IndexOpenWithImToken": "imTokenで開く",
                    "IndexOpenWithTokenPocket": "TokenPocketで開く",
                    "IndexOpenWithCoinbase": "Coinbaseで開く",
                    "IndexYouCanAlsoToo": "あなたもできます",
                    "IndexCopyThisPage": "このページのリンクをコピーして他の人に送金する",
                    "IndexCreateMyPage": "私も受け取りたい",

                    "IndexInvalidAmount": "正しい金額を入力してください",
                    "IndexTransactionFailed": "トランザクションに失敗しました",
                    "IndexErrorNetwork": "現在のネットワークはターゲットネットワークと一致しません。ネットワークを切り替えますか？",

                    "NewTitle": "プライバシーとセキュリティ",
                    "NewLine1": "このアプリケーションは、支払い情報の表示とウォレットの呼び出し機能のみを提供します。",
                    "NewLine2": "このアプリケーションはサーバーサイドとのやり取りを行わず、ユーザー情報は収集せず、ユーザー情報は保存しません。",
                    "NewLine3": "このアプリケーションは支払い情報の真正性を検証する責任を負いません。また、支払い情報の合法性を検証する責任も負いません。",
                    "NewLine4": "このアプリケーションは手数料を請求しません。取引資金は直接受信アカウントに入金されます。",
                    "NewLine5": "このアプリケーションを使用すると、上記の条件に同意したことになります。",

                    "NewPersonal": "個人支払い",
                    "NewPersonalLine1": "個人支払いとは、支払いリンクを支払い者に送信することを意味します",
                    "NewPersonalLine2": "支払い者は、QRコードをスキャンしたり、支払いアドレスをコピーしたりして支払いを完了できます",
                    "NewPersonalLine3": "支払い者は、サードパーティのウォレットを開くことでも支払いを完了できます",
                    "NewPersonalButton": "支払いリンクを生成する",

                    "NewOrder": "注文支払い",

                    "PersonalSelectChain": "チェーンを選択",
                    "PersonalSelectTokenType": "通貨タイプを選択",
                    "PersonalContractAddress": "ERC20/BIP20契約アドレス",
                    "PersonalContractAddressPlaceholder": "契約アドレスを入力してください",
                    "PersonalTo": "に",
                    "PersonalToPlaceholder": "支払いアドレスを入力してください",
                    "PersonalAmount": "量",
                    "PersonalAmountPlaceholder": "金額を入力してください",
                    "PersonalMemo": "メモ",
                    "PersonalMemoPlaceholder": "メモを入力してください",
                    "PersonalGenerateLink": "支払いリンクを生成する",
                    "PersonalGenerateLinkSuccess": "支払いリンクが正常に生成されました",
                    "PersonalCopyLink": "支払いリンクをコピー",
                    "PersonalCopyLinkSuccess": "支払いリンクが正常にコピーされました",
                    "PersonalClose": "閉じる",

                    "ButtonPay": "支払う",
                    "ResultPageViewOnScan": "ブロックチェーンエクスプローラーで表示",
                    "ResultPageCreateMyOwnTransferLink": "私も支払いページを作成したい",

                    "PaymentPagePayButton": "今すぐ支払う {{amount}} {{symbol}}",
                    "PaymentPageQRCode": "QRコード",

                    "PaymentPageAllowanceInfo": "許可",
                    "PaymentPageAllowanceNotEnough": "支払い契約への許可不足",
                    "PaymentPageDoApprove": "{{amount}} {{symbol}}を承認する",
                }
            },

            // 西班牙语

            es: {
                translation: {
                    "AppName": "Transferencia",
                    "IndexTitle": "Estás pagando",
                    "IndexAmountInputPlaceholder": "Por favor ingrese la cantidad",
                    "IndexCopySuccess": "Copia exitosa",
                    "IndexCopyAddress": "Copiar dirección",
                    "IndexTo": "Pagar a",
                    "IndexQRCode": "Código QR",
                    "IndexThisAddressIsMarkedAs": "Esta dirección está marcada como:",
                    "IndexThisAddressIsMarkedAsNone": "Ninguna",
                    "IndexTransactionMemo": "Memo de transacción:",
                    "IndexNotSupportLine1": "No admite pagos directos, tal vez:",
                    "IndexNotSupportLine2": "1. No ha instalado la billetera o está abierta en el navegador DAPP",
                    "IndexNotSupportLine3": "2. Su billetera no admite la cadena actual",
                    "IndexYouCanAlso": "También puedes",
                    "IndexOpenWithOKXWallet": "Abrir con OKX Wallet",
                    "IndexOpenWithMetamask": "Abrir con Metamask",
                    "IndexOpenWithImToken": "Abrir con imToken",
                    "IndexOpenWithTokenPocket": "Abrir con TokenPocket",
                    "IndexOpenWithCoinbase": "Abrir con Coinbase",
                    "IndexYouCanAlsoToo": "También puedes",
                    "IndexCopyThisPage": "Copie el enlace de esta página a otros",
                    "IndexCreateMyPage": "Generar mi enlace de pago",

                    "IndexInvalidAmount": "Ingrese la cantidad correcta",
                    "IndexTransactionFailed": "Transacción fallida",
                    "IndexErrorNetwork": "La red actual no coincide con la red de destino, ¿cambiar la red?",

                    "NewTitle": "Privacidad y seguridad",
                    "NewLine1": "Esta aplicación solo proporciona la visualización de información de pago y funciones de invocación de billetera.",
                    "NewLine2": "Esta aplicación no interactúa con el lado del servidor, no recopila ninguna información del usuario y no guarda ninguna información del usuario.",
                    "NewLine3": "Esta aplicación no es responsable de verificar la autenticidad de la información de pago, ni es responsable de verificar la legalidad de la información de pago.",
                    "NewLine4": "Esta aplicación no cobra ninguna tarifa de transacción, y los fondos de la transacción ingresarán directamente a la cuenta receptora.",
                    "NewLine5": "El uso de esta aplicación significa que acepta los términos anteriores.",

                    "NewPersonal": "Pago personal",
                    "NewPersonalLine1": "El pago personal significa enviar un enlace de pago al pagador",
                    "NewPersonalLine2": "El pagador puede completar el pago escaneando el código QR, copiando la dirección de pago y transfiriendo dinero",
                    "NewPersonalLine3": "El pagador también puede completar el pago abriendo la billetera de terceros",
                    "NewPersonalButton": "Generar mi enlace de pago",

                    "NewOrder": "Pago de pedido",

                    "PersonalSelectChain": "Seleccionar cadena",
                    "PersonalSelectTokenType": "Seleccionar tipo de moneda",
                    "PersonalContractAddress": "Dirección del contrato ERC20/BIP20",
                    "PersonalContractAddressPlaceholder": "Ingrese la dirección del contrato",
                    "PersonalTo": "A",
                    "PersonalToPlaceholder": "Ingrese la dirección",
                    "PersonalAmount": "Cantidad",
                    "PersonalAmountPlaceholder": "Ingrese la cantidad",
                    "PersonalMemo": "Memo",
                    "PersonalMemoPlaceholder": "Ingrese el memo",
                    "PersonalGenerateLink": "Generar enlace de pago",
                    "PersonalGenerateLinkSuccess": "Enlace de pago generado con éxito",
                    "PersonalCopyLink": "Copiar enlace de pago",
                    "PersonalCopyLinkSuccess": "Enlace de pago copiado con éxito",
                    "PersonalClose": "Cerrar",

                    "ButtonPay": "Pagar",
                    "ResultPageViewOnScan": "Ver en explorador de blockchain",
                    "ResultPageCreateMyOwnTransferLink": "También quiero crear una página de pago",

                    "PaymentPagePayButton": "Pagar ahora {{amount}} {{symbol}}",
                    "PaymentPageQRCode": "Código QR",

                    "PaymentPageAllowanceInfo": "Autorización",
                    "PaymentPageAllowanceNotEnough": "La autorización al contrato de pago no es suficiente",
                    "PaymentPageDoApprove": "Aprobar {{amount}} {{symbol}}",
                }
            },
            // 法语
            fr: {
                translation: {
                    "AppName": "Transfert",
                    "IndexTitle": "Vous payez",
                    "IndexAmountInputPlaceholder": "Veuillez saisir le montant",
                    "IndexCopySuccess": "Copie réussie",
                    "IndexCopyAddress": "Copier l'adresse",
                    "IndexTo": "Payer à",
                    "IndexQRCode": "Code QR",
                    "IndexThisAddressIsMarkedAs": "Cette adresse est marquée comme:",
                    "IndexThisAddressIsMarkedAsNone": "Aucun",
                    "IndexTransactionMemo": "Mémo de transaction:",
                    "IndexNotSupportLine1": "Ne prend pas en charge le paiement direct, peut-être:",
                    "IndexNotSupportLine2": "1. Vous n'avez pas installé de portefeuille ouvert dans le navigateur DAPP",
                    "IndexNotSupportLine3": "2. Votre portefeuille ne prend pas en charge la chaîne actuelle",
                    "IndexYouCanAlso": "Vous pouvez aussi",
                    "IndexOpenWithOKXWallet": "Ouvrir avec OKX Wallet",
                    "IndexOpenWithMetamask": "Ouvrir avec Metamask",
                    "IndexOpenWithImToken": "Ouvrir avec imToken",
                    "IndexOpenWithTokenPocket": "Ouvrir avec TokenPocket",
                    "IndexOpenWithCoinbase": "Ouvrir avec Coinbase",
                    "IndexYouCanAlsoToo": "Vous pouvez aussi",
                    "IndexCopyThisPage": "Copiez le lien de cette page vers d'autres",
                    "IndexCreateMyPage": "Générer mon lien de paiement",

                    "IndexInvalidAmount": "Veuillez saisir le montant correct",
                    "IndexTransactionFailed": "Échec de la transaction",
                    "IndexErrorNetwork": "Le réseau actuel ne correspond pas au réseau cible, voulez-vous changer de réseau?",

                    "NewTitle": "Confidentialité et sécurité",
                    "NewLine1": "Cette application ne fournit que l'affichage des informations de paiement et les fonctions d'appel de portefeuille.",
                    "NewLine2": "Cette application n'interagit pas avec le côté serveur, ne collecte aucune information utilisateur et ne sauvegarde aucune information utilisateur.",
                    "NewLine3": "Cette application n'est pas responsable de la vérification de l'authenticité des informations de paiement, ni de la vérification de la légalité des informations de paiement.",
                    "NewLine4": "Cette application ne facture aucun frais detransaction, et les fonds de transaction entreront directement dans le compte récepteur.",

                    "NewPersonal": "Paiement personnel",
                    "NewPersonalLine1": "Le paiement personnel signifie envoyer un lien de paiement au payeur",
                    "NewPersonalLine2": "Le payeur peut effectuer le paiement en scannant le code QR, en copiant l'adresse de paiement et en transférant de l'argent",
                    "NewPersonalLine3": "Le payeur peut également effectuer le paiement en ouvrant le portefeuille tiers",
                    "NewPersonalButton": "Générer mon lien de paiement",

                    "NewOrder": "Paiement de commande",

                    "PersonalSelectChain": "Sélectionner la chaîne",
                    "PersonalSelectTokenType": "Sélectionner le type de devise",
                    "PersonalContractAddress": "Adresse de contrat ERC20/BIP20",
                    "PersonalContractAddressPlaceholder": "Veuillez saisir l'adresse du contrat",
                    "PersonalTo": "À",
                    "PersonalToPlaceholder": "Veuillez saisir l'adresse",
                    "PersonalAmount": "Montant",
                    "PersonalAmountPlaceholder": "Veuillez saisir le montant",
                    "PersonalMemo": "Mémo",
                    "PersonalMemoPlaceholder": "Veuillez saisir le mémo",
                    "PersonalGenerateLink": "Générer le lien de paiement",
                    "PersonalGenerateLinkSuccess": "Lien de paiement généré avec succès",
                    "PersonalCopyLink": "Copier le lien de paiement",
                    "PersonalCopyLinkSuccess": "Lien de paiement copié avec succès",
                    "PersonalClose": "Fermer",

                    "ButtonPay": "Payer",
                    "ResultPageViewOnScan": "Voir sur l'explorateur de blockchain",
                    "ResultPageCreateMyOwnTransferLink": "Je veux aussi créer une page de paiement",

                    "PaymentPagePayButton": "Payer maintenant {{amount}} {{symbol}}",
                    "PaymentPageQRCode": "Code QR",

                    "PaymentPageAllowanceInfo": "Autorisation",
                    "PaymentPageAllowanceNotEnough": "L'autorisation au contrat de paiement n'est pas suffisante",
                    "PaymentPageDoApprove": "Approuver {{amount}} {{symbol}}",

                }
            },
            de:{
                translation: {
                    "AppName": "Überweisung",
                    "IndexTitle": "Sie zahlen",
                    "IndexAmountInputPlaceholder": "Bitte geben Sie den Betrag ein",
                    "IndexCopySuccess": "Kopieren erfolgreich",
                    "IndexCopyAddress": "Adresse kopieren",
                    "IndexTo": "Zahlen an",
                    "IndexQRCode": "QR-Code",
                    "IndexThisAddressIsMarkedAs": "Diese Adresse ist markiert als:",
                    "IndexThisAddressIsMarkedAsNone": "Keiner",
                    "IndexTransactionMemo": "Transaktionsmemo:",
                    "IndexNotSupportLine1": "Direkte Zahlung wird nicht unterstützt, vielleicht:",
                    "IndexNotSupportLine2": "1. Sie haben keine Brieftasche installiert oder im DAPP-Browser geöffnet",
                    "IndexNotSupportLine3": "2. Ihre Brieftasche unterstützt die aktuelle Kette nicht",
                    "IndexYouCanAlso": "Sie können auch",
                    "IndexOpenWithOKXWallet": "Mit OKX Wallet öffnen",
                    "IndexOpenWithMetamask": "Mit Metamask öffnen",
                    "IndexOpenWithImToken": "Mit imToken öffnen",
                    "IndexOpenWithTokenPocket": "Mit TokenPocket öffnen",
                    "IndexOpenWithCoinbase": "Mit Coinbase öffnen",
                    "IndexYouCanAlsoToo": "Sie können auch",
                    "IndexCopyThisPage": "Kopieren Sie den Link dieser Seite an andere",
                    "IndexCreateMyPage": "Generieren Sie meinen Zahlungslink",

                    "IndexInvalidAmount": "Bitte geben Sie den richtigen Betrag ein",
                    "IndexTransactionFailed": "Transaktion fehlgeschlagen",
                    "IndexErrorNetwork": "Das aktuelle Netzwerk stimmt nicht mit dem Zielnetzwerk überein. Soll das Netzwerk gewechselt werden?",
    
                    "NewTitle": "Datenschutz und Sicherheit",
                    "NewLine1": "Diese Anwendung bietet nur die Anzeige von Zahlungsinformationen und die Funktion zum Aufrufen von Brieftaschen.",
                    "NewLine2": "Diese Anwendung interagiert nicht mit der Serverseite, sammelt keine Benutzerinformationen und speichert keine Benutzerinformationen.",
                    "NewLine3": "Diese Anwendung ist nicht dafür verantwortlich, die Echtheit der Zahlungsinformationen zu überprüfen, noch ist sie dafür verantwortlich, die Rechtmäßigkeit der Zahlungsinformationen zu überprüfen.",
                    "NewLine4": "Diese Anwendung erhebt keine Transaktionsgebühren, und die Transaktionsfonds werden direkt auf das Empfängerkonto überwiesen.",
                    "NewPersonal": "Persönliche Zahlung",
                    "NewPersonalLine1": "Persönliche Zahlung bedeutet, dass ein Zahlungslink an den Zahlenden gesendet wird",
                    "NewPersonalLine2": "Der Zahlende kann die Zahlung abschließen, indem er den QR-Code scannt, die Zahlungsadresse kopiert und Geld überweist",
                    "NewPersonalLine3": "Der Zahlende kann die Zahlung auch abschließen, indem er die Brieftasche eines Drittanbieters öffnet",
                    "NewPersonalButton": "Generieren Sie meinen Zahlungslink",
    
                    "NewOrder": "Bestellung bezahlen",
    
                    "PersonalSelectChain": "Kette auswählen",
                    "PersonalSelectTokenType": "Währungstyp auswählen",
                    "PersonalContractAddress": "ERC20/BIP20-Vertragsadresse",
                    "PersonalContractAddressPlaceholder": "Bitte geben Sie die Vertragsadresse ein",
                    "PersonalTo": "An",
                    "PersonalToPlaceholder": "Bitte geben Sie die Adresse ein",
                    "PersonalAmount": "Menge",
                    "PersonalAmountPlaceholder": "Bitte geben Sie den Betrag ein",
                    "PersonalMemo": "Memo",
                    "PersonalMemoPlaceholder": "Bitte geben Sie das Memo ein",
                    "PersonalGenerateLink": "Zahlungslink generieren",
                    "PersonalGenerateLinkSuccess": "Zahlungslink erfolgreich generiert",
                    "PersonalCopyLink": "Zahlungslink kopieren",
                    "PersonalCopyLinkSuccess": "Zahlungslink erfolgreich kopiert",
                    "PersonalClose": "Schließen",

                    "ButtonPay": "Bezahlen",
                    "ResultPageViewOnScan": "Auf Blockchain Explorer anzeigen",
                    "ResultPageCreateMyOwnTransferLink": "Ich möchte auch eine Zahlungsseite erstellen",

                    "PaymentPagePayButton": "Jetzt bezahlen {{amount}} {{symbol}}",
                    "PaymentPageQRCode": "QR-Code",

                    "PaymentPageAllowanceInfo": "Genehmigung",
                    "PaymentPageAllowanceNotEnough": "Die Genehmigung für den Zahlungsvertrag ist nicht ausreichend",
                    "PaymentPageDoApprove": "{{amount}} {{symbol}} genehmigen",
    
                }
            }
        },
        
    });
export default i18n;