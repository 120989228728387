import {post} from './rest';

const API = 'https://facelive.ing.cc'

export const createLivenessSession = async (params) => {
    return post(API + '/create', params );
}

export const getLivenessSessionState = async (sessionId) => {
    return post(API + '/check', {sessionId});
}