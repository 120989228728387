import React from "react";

class HelpPage extends React.Component {
    render() {
        return (
            <div>
                <h1>收款</h1>
                <p>Help page</p>
            </div>
        );
    }
}

export default HelpPage;