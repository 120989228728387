import React from "react";
import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";
import Header from "../../components/common/Header";

// connect from dapp.js
import {
    connect,
    ABI_MCH,
    ABI_PAY_2_ADDRESS
} from "../../api/dapp.js";

import { useNavigate } from "react-router-dom";

import { getChainById } from "../../api/constents";

// ethers
import {
    ethers,
} from "ethers";
import Bottom from "../../components/common/Bottom.js";
import TLoading from "../../components/common/TLoading.js";


class _NewMch extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMch: false,
            chain: null,
            address: null,
            balance: null,
            fee: null,
            loading: true,
            tokens: []
        }
    }

    componentDidMount() {
        this._init();
    }

    _init = async () => {
        const options = await connect();
        const chain = getChainById(options.chainId);

        this.setState({
            address: options.address,
            chain: chain,
        });

        if (!chain.mchContract) {
            alert("This chain does not support merchant.");
            return;
        }

        this.setState({
            loading: true
        });

        let contract = new ethers.Contract(chain.mchContract, ABI_MCH, options.provider);

        // balance
        const balance = await contract.balanceOf(options.address);
        console.log("balance", balance.toString());

        this.setState({
            isMch: balance > 0,
            balance: balance
        });

        // iterator tokens
        const tokens = [];
        for (let i = 0; i < balance; i++) {
            const tokenId = await contract.tokenOfOwnerByIndex(options.address, i);
            const count = await this._loadCount(options, tokenId);
            tokens.push({
                tokenId: tokenId,
                count: count
            });
        }
        this.setState({
            tokens: tokens
        });

        // fee
        const fee = await contract.getFee();
        this.setState({
            fee: fee
        });

        this.setState({
            loading: false
        });
    }

    _loadCount = async (options, tokenId) => {
        const contract = new ethers.Contract(this.state.chain.payContract, ABI_PAY_2_ADDRESS, options.provider);
        const count = await contract.getCount(tokenId);
        return count;
    }

    _doMint = async () => {
        const options = await connect();
        const signer = await options.provider.getSigner();

        if (!this.state.chain.mchContract) {
            alert("This chain does not support merchant.");
            return;
        }

        const contract = new ethers.Contract(this.state.chain.mchContract, ABI_MCH, signer);
        try {
            const recp = await contract.mint({
                value: this.state.fee
            });
            console.log(recp);

            this.props.onTx(recp, this.state.chain.chainId);
        } catch (e) {
            alert(e.reason);
        }
    }

    render() {
        const state = this.state
        const props = this.props
        return (
            <div>
                <Header title="Merchant" />
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    backgroundColor: "#f5f5f5",
                }}>
                    <div style={{
                        padding: "0.5rem",
                        maxWidth: "600px",
                        width: "100%",
                    }}>
                        <Container>
                            <Row>
                                <Col>
                                    <Alert variant="light">
                                        <div style={{
                                            wordBreak: "break-all"
                                        }}>
                                            {state.address}
                                        </div>
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {state.isMch ? <Alert
                                        variant="success"
                                    >
                                        <div style={
                                            {
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",

                                            }
                                        }>
                                            <div>You are a merchant.</div>
                                            <Button
                                                variant="warning"
                                                size="sm"
                                                onClick={() => {
                                                    this._doMint();
                                                }}
                                            >
                                                New ({state.fee ? ethers.formatUnits(state.fee, state.chain.decimal) : "Loading..."} {state.chain ? state.chain.symbol : ""})
                                            </Button>
                                        </div>
                                    </Alert> :
                                        <Alert
                                            variant="warning"
                                        >
                                            You are not a merchant.
                                        </Alert>}
                                </Col>
                            </Row>
                            {state.isMch ? <Row>
                                <Col>

                                    {state.tokens.map((token, index) => {
                                        return <div style={{ marginTop: '1rem' }} key={index} >
                                            <Card>
                                                <Card.Body>
                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                    }}>
                                                        <div>
                                                            <div>Mch ID: <b>{token.tokenId.toString()}</b></div>
                                                            <div>Count: {token.count + ''} </div>
                                                        </div>
                                                        <div>
                                                            <Button variant="link" size="sm" onClick={() => {
                                                                window.location.href = "/payment/create?mch=" + token.tokenId.toString() + '&chainId=' + state.chain.chainId;
                                                            }}>Create Order</Button>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    })}
                                </Col>
                            </Row> : <Row>
                                <Col>
                                    <Card>
                                        <Card.Header>
                                            创建新的支付商户身份
                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Text>
                                                You can create a merchant by creating a NFT.
                                            </Card.Text>
                                            <Card.Text>
                                                Mint: <span style={{
                                                    fontWeight: "bold",
                                                    color: "orange"
                                                }}>{state.fee ? ethers.formatUnits(state.fee, state.chain.decimal) : "Loading..."} {state.chain ? state.chain.symbol : ""}</span>
                                            </Card.Text>
                                            <Card.Text>
                                                Click button to create a new NFT.
                                            </Card.Text>
                                            <Card.Text>
                                                <Button
                                                    variant="warning"
                                                    onClick={() => {
                                                        this._doMint();
                                                    }}
                                                >
                                                    Pay {state.fee ? ethers.formatUnits(state.fee, state.chain.decimal) : "Loading..."} {state.chain ? state.chain.symbol : ""} to create a new merchant
                                                </Button>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>}
                        </Container>
                    </div>
                </div>
                <div style={{
                    position: "absolute",
                    bottom: "0",
                    width: "100%"

                }}>
                    <Bottom />
                </div>
                <TLoading show={state.loading} />
            </div>
        );
    }
}

function NewMchPage() {
    const navigate = useNavigate();

    return <_NewMch onTx={(tx, chainId) => {
        navigate('/result', {
            state: {
                txHash: tx.hash,
                chainId: chainId,
                re: '',
            }
        });
    }
    } />;
}

export default NewMchPage;
