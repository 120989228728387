import React from "react";

import Bottom from "../../components/common/Bottom";
import { Button, Form } from "react-bootstrap";

import { CHAIN_CONFIG, getChainById } from "../../api/constents";
import { connect, isInWeb3Browser, getERC20Info } from "../../api/dapp";
// Alert
import Alert from 'react-bootstrap/Alert'
// import copy
import copy from 'copy-to-clipboard';
import { ethers } from "ethers";

// QRCode
import QRCode from 'qrcode.react';

import { useTranslation } from 'react-i18next';
import '../../i18n.js'
import { t } from "i18next";
// Header
import Header from "../../components/common/Header";

class CreatePersonalCollectPageDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            chainId: null,
            chain: null,
            isInWeb3Browser: false,

            params: {
                type: 0,
                to: null,

                symbol: null,
                contract: null,
                decimals: null,

                amount: null,
                message: null,

            },

            buttonEnable: false,

            provider: null,

            url: null,
            showUrl: false,
            showQRCode: true,
        };
    }

    async componentDidMount() {
        this._init();
    }

    _init = async () => {
        const isWeb3 = this._checkBrowser();

        if (!isWeb3) {

            const chain = getChainById(1);
            this.setState({
                chainId: 1,
                chain: chain,
            });
            return;
        } else {
            const res = await connect();
            const chain = getChainById(res.chainId);

            // set params.to
            const params = this.state.params;
            params.to = res.address;

            this.setState({
                chainId: res.chainId,
                chain: chain,
                provider: res.provider,
                params: params,
            });
        }
    }

    _checkBrowser = () => {
        const res = isInWeb3Browser();
        this.setState({
            isInWeb3Browser: res,
        });
        return res;
    }

    _updateButtonEnable = async () => {
        const res = await this._isAllValid();
        console.log("is all valid = " + res);
        this.setState({
            buttonEnable: res,
        });
    }

    _isAllValid = async () => {
        const params = {
            to: this.state.params.to,
            amount: this.state.params.amount,
            message: this.state.params.message,
            chainId: this.state.chainId,
            type: this.state.params.type,
            contract: this.state.params.contract,
            symbol: this.state.params.symbol,
            decimals: this.state.params.decimals,
        };

        if (!params.to || !/^0x[a-fA-F0-9]{40}$/.test(params.to)) {
            console.log("to = " + params.to);
            return false;
        }

        if (!params.amount || !/^[0-9]+([.]{1}[0-9]+){0,1}$/.test(params.amount)) {
            //log
            console.log("amount = " + params.amount);
            return false;
        }

        // if (!params.message || !/^[0-9a-zA-Z\u4e00-\u9fa5]+$/.test(params.message)) {
        //     console.log("message = " + params.message);
        //     return false;
        // }

        if (params.type == 1) {
            if (!params.contract || !/^0x[a-fA-F0-9]{40}$/.test(params.contract)) {
                console.log("contract = " + params.contract);
                return false;
            }
        }

        return true;
    }

    _generateUrl = async () => {

        const base = 'https://c.ing.cc/'
        const params = {
            to: this.state.params.to,
            amount: this.state.params.amount,
            message: this.state.params.message,
            chainId: this.state.chainId,
            type: this.state.params.type,
            contract: this.state.params.contract,
            symbol: this.state.params.symbol,
            decimals: this.state.params.decimals,
        };

        let url = base + '?';
        url += 'to=' + params.to;
        url += '&chainId=' + params.chainId;
        url += '&type=' + params.type;

        if (params.type == 1) {
            url += '&contract=' + params.contract;
            url += '&symbol=' + params.symbol;
            url += '&decimals=' + params.decimals;
            url += '&amount=' + ethers.parseUnits(params.amount, params.decimals);
        } else {
            url += '&symbol=' + this.state.chain.symbol;
            url += '&decimals=' + this.state.chain.decimals;
            url += '&amount=' + ethers.parseUnits(params.amount, this.state.chain.decimals);
        }

        if (params.message) {
            url += '&message=' + params.message;
        }

        this.setState({
            url: url,
            showUrl: true,
        });

    }

    render() {
        const state = this.state;
        const { t } = this.props;
        return (
            <div>
                <Header />
                <div style={{
                    padding: "1rem",
                }}>
                    <div>
                        <Form>
                            {/* 
                                input field
                                    Select Chain
                                    Type: Select (ERC20, Coin)
                                    My account
                                    Token contract (IF ERC20)
                                    Collect amount
                                    Collect memo
                            */}

                            {state.chain ? <Form.Group className="mb-3">
                                <Form.Label>
                                    {t('PersonalSelectChain')}
                                </Form.Label>
                                <Form.Control as="select" onChange={(e) => {
                                    console.log("select chain = " + e.target.value);
                                    const chain = getChainById(e.target.value);
                                    this.setState({
                                        chainId: e.target.value,
                                        chain: chain
                                    });
                                    this._updateButtonEnable();

                                }} defaultValue={state.chain.chainId} size="lg">
                                    {/* iterator CONFIG_CHAIN */}
                                    {CHAIN_CONFIG.map((item, index) => {
                                        return (
                                            <option key={index} value={item.chainId}>{item.name}</option>
                                        );
                                    })}
                                </Form.Control>
                            </Form.Group> : null}
                            {state.chain ? <Form.Group className="mb-3">
                                <Form.Label>
                                    {t('PersonalSelectTokenType')}
                                </Form.Label>
                                <Form.Control as="select" size="lg" onChange={(e) => {
                                    // type
                                    console.log("select type = " + e.target.value);
                                    const params = this.state.params;
                                    params.type = e.target.value;

                                    if (params.type == 0) {
                                        params.symbol = state.chain.symbol;
                                        params.decimals = state.chain.decimals;
                                    } else {
                                        params.symbol = null;
                                        params.decimals = null;
                                        params.contract = null;
                                    }

                                    this.setState({
                                        params: params
                                    });

                                    this._updateButtonEnable();
                                }}>
                                    <option value="0">{state.chain.symbol}</option>
                                    <option value="1">ERC20/BIP20</option>
                                </Form.Control>
                            </Form.Group> : null}

                            {(state.params.type == 1) ? <Form.Group className="mb-3">
                                <Form.Label>
                                    {t('PersonalContractAddress')}
                                </Form.Label>
                                <Form.Control type="text" placeholder={t('PersonalContractAddressPlaceholder')} size="lg" onInput={(e) => {
                                    const params = this.state.params;
                                    params.contract = e.target.value;
                                    this.setState({
                                        params: params
                                    });
                                    console.log("input contract = " + e.target.value);
                                }} onChange={async (e) => {
                                    // check contract address is valid or not
                                    const params = this.state.params;
                                    params.contract = e.target.value;

                                    console.log("change contract = " + e.target.value);

                                    if (/^0x[a-fA-F0-9]{40}$/.test(params.contract)) {
                                        // valid
                                        // params.symbol = "Token";
                                        // params.decimals = 18;
                                        if (state.provider) {
                                            const info = await getERC20Info(state.provider, params.contract);
                                            params.symbol = info.symbol;
                                            params.decimals = info.decimals;

                                            // log symbol
                                            console.log("symbol = " + params.symbol);
                                            // log decimals
                                            console.log("decimals = " + params.decimals);
                                        }
                                        this.setState({
                                            params: params
                                        });
                                    }

                                    this._updateButtonEnable();
                                }}
                                    isInvalid={state.params.contract && !/^0x[a-fA-F0-9]{40}$/.test(state.params.contract)}
                                    isValid={state.params.contract && /^0x[a-fA-F0-9]{40}$/.test(state.params.contract)}
                                />
                            </Form.Group> : null}
                            {(state.params.type == 1 && state.params.symbol) ? <div>
                                <Alert variant="success">
                                    <span style={{ color: '#000' }}>Token: </span><span style={{ fontStyle: 'bold' }}> {state.params.symbol}</span> <span style={{ color: '#000' }}>Contract:</span> [<a ref='noreferrer' target="_blank" href={state.chain.explorer + '/token/' + state.params.contract}>{state.params.contract.replace(/^(.{6})(.*)(.{4})$/, "$1...$3")}</a>]
                                </Alert>
                            </div> : null}
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    {t('PersonalTo')}
                                </Form.Label>
                                <Form.Control type="text" placeholder={t('PersonalToPlaceholder')} size="lg" defaultValue={state.params.to} onInput={(e) => {
                                    const params = this.state.params;
                                    params.to = e.target.value;
                                    this.setState({
                                        params: params
                                    });
                                }} onChange={(e) => {
                                    // check contract address is valid or not
                                    const params = this.state.params;
                                    params.to = e.target.value;
                                    this.setState({
                                        params: params
                                    });


                                    this._updateButtonEnable();
                                }}
                                    isInvalid={state.params.to && !/^0x[a-fA-F0-9]{40}$/.test(state.params.to)}
                                    isValid={state.params.to && /^0x[a-fA-F0-9]{40}$/.test(state.params.to)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t('PersonalAmount')}</Form.Label>
                                <Form.Control maxLength={19} type="number" placeholder={t('PersonalAmountPlaceholder')} size="lg"

                                    onInput={(e) => {
                                        const params = this.state.params;
                                        params.amount = e.target.value;
                                        this.setState({
                                            params: params
                                        });
                                    }} onChange={(e) => {
                                        // check contract address is valid or not
                                        const params = this.state.params;
                                        params.amount = e.target.value;
                                        this.setState({
                                            params: params
                                        });

                                        this._updateButtonEnable();
                                    }}
                                    isInvalid={state.params.amount && !/^[0-9]+([.]{1}[0-9]+){0,1}$/.test(state.params.amount)}
                                    isValid={state.params.amount && /^[0-9]+([.]{1}[0-9]+){0,1}$/.test(state.params.amount)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t('PersonalMemo')}</Form.Label>
                                <Form.Control maxLength={20} type="text" placeholder={t('PersonalMemoPlaceholder')} size="lg"

                                    onInput={(e) => {
                                        const params = this.state.params;
                                        params.message = e.target.value;
                                        this.setState({
                                            params: params
                                        });
                                    }} onChange={(e) => {
                                        // check contract address is valid or not
                                        const params = this.state.params;
                                        params.message = e.target.value;
                                        this.setState({
                                            params: params
                                        });

                                        this._updateButtonEnable();

                                    }}
                                    isInvalid={state.params.message && !/^[0-9a-zA-Z\u4e00-\u9fa5]+$/.test(state.params.message)}
                                    isValid={state.params.message && /^[0-9a-zA-Z\u4e00-\u9fa5]+$/.test(state.params.message)}
                                />
                            </Form.Group>
                        </Form>

                        <div className="d-grid gap-2" style={{ marginTop: '2rem' }}  >
                            <Button variant="primary" size="lg" disabled={!state.buttonEnable}
                                onClick={(e) => {
                                    this._generateUrl();
                                }}
                            >
                                {t('PersonalGenerateLink')}
                            </Button>
                        </div>
                    </div>
                </div>

                {state.showUrl ? <div
                    style={{
                        position: 'fixed',
                        top: '0px',
                        left: '0px',
                        width: '100%',
                        height: '100%',
                        zIndex: '100',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                    }}
                ><div style={{
                    padding: "1rem",
                    position: 'fixed',
                    top: '40%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: '101',
                    width: '90%',
                    wordBreak: 'break-all',
                }}>
                        <Alert variant="success">
                            <Alert.Heading>
                                {t('PersonalGenerateLinkSuccess')}
                            </Alert.Heading>
                            <p>
                                <a rel='noreferrer' target="_blank" href={state.url}>{state.url}</a>
                            </p>
                            {state.showQRCode ? <center>
                                <QRCode value={state.url}
                                    size={200} fgColor="#000000" bgColor="#ffffff"
                                />
                            </center> : null}
                            <hr />
                            <div className="d-flex justify-content-end">
                                {/* 复制 */}
                                <Button variant="outline-success" onClick={(e) => {
                                    copy(state.url);

                                    // show alert
                                    alert(t('PersonalCopyLinkSuccess'));
                                }}>
                                    {t('PersonalCopyLink')}
                                </Button>
                                <div style={{ width: '1rem' }}></div>
                                {/* 二维码 */}
                                {/* <Button variant="outline-success" onClick={(e) => {
                                    this.setState({
                                        showQRCode: !state.showQRCode,
                                    });
                                }}>
                                    {state.showQRCode ? "隐藏二维码" : "显示二维码"}
                                </Button>
                                <div style={{ width: '1rem' }}></div> */}
                                {/* 关闭 */}
                                <Button onClick={() => {
                                    this.setState({
                                        showUrl: false,
                                    });
                                }} variant="outline-success">
                                    {t('PersonalClose')}
                                </Button>
                            </div>
                        </Alert>
                    </div></div> : null}
                <div style={{
                    height: '5rem'
                }}>

                </div>
                <div style={{
                    position: 'fixed',
                    bottom: '0px',
                    width: '100%',

                }}>
                    <Bottom />
                </div>
            </div>
        );
    }
}

function CreatePersonalCollectPage(props) {

    const { t, i18n } = useTranslation();

    return (
        <div>
            <CreatePersonalCollectPageDetail
                t={t}
                i18n={i18n}
            />
        </div>
    );
}


export default CreatePersonalCollectPage;