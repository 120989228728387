import React from "react"

import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Bottom from "../components/common/Bottom";
import Header from "../components/common/Header";

import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";

import copy from 'copy-to-clipboard';

import {
    connect,
    isInWeb3Browser
} from '../api/dapp'

import { ethers } from 'ethers';

import {
    postJson
} from '../api/rest';

import { getChainById } from "../api/constents";
import Deeplink from "../components/common/Deeplink";

class _SignPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            chain: null,
            isInWeb3Browser: false,
            signedMessage: null,
            showResult: false,
            address: null,
        }

    }

    componentDidMount() {
        this._init();
    }

    _init = async () => {

        const _isInWeb3Browser = await isInWeb3Browser();

        if (!_isInWeb3Browser) {
            return;
        }

        const res = await connect();
        if (res) {
            this.setState({
                chain: getChainById(res.chainId),
                isInWeb3Browser: _isInWeb3Browser,
                address: res.address,
            })
        }
    }

    doSign = async () => {
        const { t } = this.props;
        const state = this.state;
        if (!state.chain) {
            alert(t('IndexPleaseOpenWallet'));
            return;
        }
        // const res = await window.ethereum.request({
        //     method: 'personal_sign',
        //     params: [this.props.message, window.ethereum.selectedAddress],
        // });

        const res = await connect();
        const signer = await res.provider.getSigner();
        const signMessage = await signer.signMessage(this.props.message);

        this.setState({
            signedMessage: signMessage,
            showResult: true,
            address: res.address
        })
    }

    short = async (url) => {
        // TODO 缩短url
        const shortUrl = 'https://s.ing.cc/';
        const res = await postJson(shortUrl, {
            url: url,
            timeout: 3601
        });
        console.log(res);
        return res.key;
    }

    render() {
        const props = this.props;
        const { t } = this.props;
        const state = this.state

        return (
            <div>
                <Header title="Signature" />
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f5f5f5",
                    marginTop: '1rem',
                    padding: '1rem',
                }}>
                    <div style={{
                        maxWidth: "800px",
                        display: 'unset'
                    }}>
                        {(props.message && props.message.startsWith('0x')) ?
                            <Alert
                                variant="danger"
                            >
                                如果您看不懂签名的数据内容，请谨慎对该数据签名和传播该签名，以避免造成不必要的损失。
                            </Alert> : null}
                        {props.message && props.message.startsWith('WRB verification') ? <Alert variant="success">
                            WRB数字签名认证仅用于TG账号绑定，待签署内容为可读明文，请勿将签署后的数字签名发送给他人。
                        </Alert> : null}
                        <Card>
                            <Card.Header>
                                以下是待签名的数据
                            </Card.Header>
                            <Card.Body>
                                <Alert variant="info" >
                                    <div style={{
                                        wordBreak: 'break-all',
                                        fontWeight: 'bold',
                                    }}>{props.message}</div>
                                </Alert>
                            </Card.Body>
                        </Card>
                        <div className="d-grid gap-2" style={{ marginTop: '1rem' }}>
                            <Button variant="primary" block="true" size="lg"
                                onClick={this.doSign}
                            >
                                使用钱包签名
                            </Button>
                        </div>
                        <div style={{ marginTop: '1rem' }}>
                            {t('IndexYouCanAlso')}
                        </div>

                        <Deeplink />
                    </div>
                </div>
                <div style={{
                    height: '5rem'
                }}>

                </div>
                <div style={{
                    position: 'fixed',
                    bottom: 0,
                    width: '100%'
                }}>
                    <Bottom />
                </div>
                {state.showResult ? <div
                    style={{
                        position: 'fixed',
                        top: '0px',
                        left: '0px',
                        width: '100%',
                        height: '100%',
                        zIndex: '100',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                    }}
                ><div style={{
                    padding: "1rem",
                    position: 'fixed',
                    top: '40%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: '101',
                    width: '90%',
                    wordBreak: 'break-all',
                }}>
                        <Card>
                            <Card.Header>
                                Signature
                            </Card.Header>
                            <Card.Body>
                                <b>Original data:</b>
                                <Alert variant="light">
                                    {props.message}
                                </Alert>
                                <b>Signature</b>
                                <Alert variant="info">
                                    {state.signedMessage}
                                </Alert>
                                <b>Signer</b>
                                <Alert variant="light">
                                    {state.address}
                                </Alert>
                                <hr />
                                <div className="d-flex justify-content-end">
                                    {/* 复制 */}
                                    <Button variant="outline-success" onClick={(e) => {
                                        copy(state.url);

                                        // show alert
                                        //alert(t('PersonalCopyLinkSuccess'));
                                        alert('Copy success')
                                    }}>
                                        Copy Signature
                                    </Button>
                                    <div style={{ width: '1rem' }}></div>

                                    {/* 关闭 */}
                                    <Button onClick={() => {
                                        this.setState({
                                            showUrl: false,
                                        });
                                        if (props.re && props.re.startsWith('https://')) {
                                            let url = props.re;

                                            //  这里因为tg的deeplink的长度不能超过64，所以这里需要做一下处理
                                            if (props.short) {
                                                // 如果是短链接，那么就需要把url缩短一下
                                                this.short('data:bindaddr:' + state.signedMessage + "|" + state.address).then((res) => {
                                                    // window.location.href = res;
                                                    console.log('short url', res);
                                                    if (url.endsWith('=')) {
                                                        url += encodeURIComponent(res);
                                                    } else {
                                                        if (url.indexOf('?') > -1) {
                                                            url += '&';
                                                        } else {
                                                            url += '?';
                                                        }
                                                        url += 'key=' + encodeURIComponent(res);
                                                    }
                                                    window.location.href = url;
                                                })
                                            } else {
                                                // 
                                                if (url.endsWith('=')) {
                                                    url += encodeURIComponent(state.signedMessage);
                                                } else {
                                                    if (url.indexOf('?') > -1) {
                                                        url += '&';
                                                    } else {
                                                        url += '?';
                                                    }
                                                    url += 'sign=' + encodeURIComponent(state.signedMessage);
                                                    url += '&address=' + encodeURIComponent(state.address);
                                                }
                                                window.location.href = url;
                                            }
                                        }
                                    }} variant="outline-success">
                                        {props.re ? 'Back to app' : t('PersonalClose')}
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </div></div> : null}
            </div>
        )
    }
}

function SignPage() {

    const [params] = useSearchParams();
    const { t, i18n } = useTranslation();

    return (
        <_SignPage t={t} i18n={i18n} re={params.get('re')}
            short={params.get('short') || false}
            message={params.get('message')}
        />
    )
}

export default SignPage