import React from "react";
import { useTranslation } from 'react-i18next';

class _Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showLanguage: false,
        }

    }

    onChange = (lang) => {
        this.setState({
            showLanguage: false,
        })
        this.props.i18n.changeLanguage(lang);
    }

    render() {
        const styleLangRow = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            height: '3.6rem',
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#0B57FF',
        };
        return (
            <div style={{
                backgroundColor: "#f8f9fa",
                borderBottom: "1px solid #e9ecef",

            }}>
                <div style={{
                    padding: "1rem",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    <h4>{this.props.title}</h4>
                    {/* select language */}
                    <img src={require('../../assets/lang.png')} style={{
                        width: "1.5rem",
                        height: "1.5rem",
                        cursor: "pointer",
                    }} onClick={
                        () => {
                            this.setState({
                                showLanguage: !this.state.showLanguage,
                            })
                        }
                    }
                    ></img>
                </div>
                <div style={{ zIndex: 999999, display: this.state.showLanguage ? 'flex' : 'none', flexDirection: 'column', position: 'absolute', top: '50px', right: '0px', width: '100%', backgroundColor: '#ffffff', borderRadius: '8px', padding: '10px', border: '1px solid #999999' }}>
                    <div onClick={(e) => { this.onChange('en') }}
                        style={styleLangRow}>
                        English
                    </div>
                    <div onClick={(e) => {
                        this.onChange('id')
                    }} style={styleLangRow}>
                        Bahasa
                    </div>
                    <div onClick={(e) => {
                        this.onChange('cn')
                    }} style={styleLangRow}>
                        中文简体
                    </div>
                    {/* 西班牙语 */}
                    <div onClick={(e) => {
                        this.onChange('es')
                    }} style={styleLangRow}>
                        Español
                    </div>

                    {/* 法语 */}
                    <div onClick={(e) => {
                        this.onChange('fr')
                    }} style={styleLangRow}>
                        Français
                    </div>

                    {/* 德语 */}
                    <div onClick={(e) => {
                        this.onChange('de')
                    }
                    } style={styleLangRow}>
                        Deutsch
                    </div>

                    {/* 日语 */}
                    <div onClick={(e) => {
                        this.onChange('ja')
                    }
                    } style={styleLangRow}>
                        日本語
                    </div>

                    {/* 韩语 */}
                    <div onClick={(e) => {
                        this.onChange('ko')
                    }
                    } style={styleLangRow}>
                        한국어
                        </div>

                </div>
            </div>
        );
    }
}

function Header(props) {
    const { t, i18n } = useTranslation();
    return (
        <_Header title={props.title || t('AppName')} t={t} i18n={i18n} />
    );
}

export default Header;