import React from "react";
import Header from "../../components/common/Header";
import Bottom from "../../components/common/Bottom";
import { Alert, Button, Form } from "react-bootstrap";
import { getChainById } from "../../api/constents";

import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import QRCode from 'qrcode.react';
import copy from "copy-to-clipboard";

import { ethers } from "ethers";

import {
  connect,
  switchNetwork,
  getERC20Info
} from "../../api/dapp";

class _CreatePaymentPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      chain: null,
      buttonEnable: false,
      url: null,
      showUrl: false,
      showQRCode: true,

      params: {
        type: 0,
        to: null,

        symbol: null,
        token: null,
        decimals: null,

        amount: null,
        title: null,
        desc: null,

        mchId: null,
        chainId: null,
        attachId: null,

      },

      provider: null,
    };
  }

  componentDidMount() {
    this._init();
  }

  _init = async () => {
    console.log("mchId = " + this.props.params.mch);
    const chainId = this.props.params.chainId;
    const chain = getChainById(chainId);
    this.setState({
      chain: chain
    });

    const res = await connect();
    console.log("connect res = " + res);
    if (res.chainId != chainId) {
      // switch network
      switchNetwork(chain, () => {
        // reload page
        window.location.reload();
      });
      return;
    }

    const params = this.state.params;
    params.to = res.address;

    this.setState({
      params: params,
      provider: res.provider,
    });
  }

  _updateButtonEnable = async () => {
    const res = await this._isAllValid();
    console.log("is all valid = " + res);
    this.setState({
      buttonEnable: res,
    });
  }

  _isAllValid = async () => {
    const params = this.state.params;
    if (!params.attachId || params.attachId.length == 0) {
      return false;
    }
    if (!params.to || !/^0x[a-fA-F0-9]{40}$/.test(params.to)) {
      return false;
    }
    if (!params.amount || !/^\d+(\.\d+)?$/.test(params.amount)) {
      return false;
    }
    if (!params.title || !/^[0-9a-zA-Z\u4e00-\u9fa5]+$/.test(params.title)) {
      return false;
    }
    if (!params.desc || !/^[0-9a-zA-Z\u4e00-\u9fa5]+$/.test(params.desc)) {
      return false;
    }

    if (params.type == 1) {
      if (!params.token || !/^0x[a-fA-F0-9]{40}$/.test(params.token)) {
        return false;
      }
    }

    return true;
  }

  _generateUrl = async () => {
    const base = 'https://c.ing.cc/payment'
    const params = this.state.params;

    let url = base + '?'
    console.log("mchId = " + this.props.params.mch);
    url += 'mchId=' + this.props.params.mch + '&'
    url += 'chainId=' + this.state.chain.chainId + '&'
    url += 'attachId=' + params.attachId + '&'
    url += 'to=' + params.to + '&'
    url += 'title=' + params.title + '&'
    url += 'desc=' + params.desc + '&'
    url += 'type=' + params.type + '&'

    if (params.type == 1) {
      url += 'token=' + params.token + '&'
      url += 'symbol=' + params.symbol + '&'
      url += 'decimals=' + params.decimals + '&'
      url += '&amount=' + ethers.parseUnits(params.amount, params.decimals);
    } else {
      url += 'symbol=' + this.state.chain.symbol + '&'
      url += 'decimals=' + this.state.chain.decimals + '&'
      url += '&amount=' + ethers.parseUnits(params.amount, this.state.chain.decimals);
    }

    this.setState({
      url: url,
      showUrl: true,
      showQRCode: true,
    });

  }

  render() {
    const state = this.state;
    const params = this.props.params;
    const props = this.props;
    const { t } = props;
    return (
      <div>
        <Header />
        <div style={{
          padding: "1rem",
        }}>
          <div>
            <Alert
              vairant="info"
            >
              <div>- 每个订单号只能被支付一次</div>
              <div>- 支付资金会<b>全额</b>转给收款方,合约不设任何手续费</div>
              <div>- 收款地址必须是MCH NFT的所有者</div>
              <div>- 订单创建后生成的链接为支付链接</div>
              <div>- 请自行监听合约的Pay事件获取交易结果</div>
            </Alert>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Order ID (每个订单号只能被支付一次)</Form.Label>
                <Form.Control type="number" size="lg" placeholder="订单号"
                  isInvalid={state.params.attachId && state.params.attachId.length > 0 && !/^\d{1,32}$/.test(state.params.attachId)}
                  isValid={state.params.attachId && state.params.attachId.length > 0 && /^\d{1,32}$/.test(state.params.attachId)}
                  onChange={(e) => {
                    const params = this.state.params;
                    params.attachId = e.target.value;
                    this.setState({
                      params: params
                    });
                    this._updateButtonEnable();
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>收款地址</Form.Label>
                <Form.Control type="text" size="lg" placeholder="收款地址" readOnly={true}
                  defaultValue={state.params.to}
                  isInvalid={state.params.to && !/^0x[a-fA-F0-9]{40}$/.test(state.params.to)}
                  isValid={state.params.to && /^0x[a-fA-F0-9]{40}$/.test(state.params.to)}
                />
              </Form.Group>

              {/* select token type , 0 for eth, 1 for erc20 */}
              {state.chain ? <Form.Group className="mb-3">
                <Form.Label>
                  {t('PersonalSelectTokenType')}
                </Form.Label>
                <Form.Control as="select" size="lg" onChange={(e) => {
                  // type
                  console.log("select type = " + e.target.value);
                  const params = this.state.params;
                  params.type = e.target.value;

                  if (params.type == 0) {
                    params.symbol = state.chain.symbol;
                    params.decimals = state.chain.decimals;
                  } else {
                    params.symbol = null;
                    params.decimals = null;
                    params.token = null;
                  }

                  this.setState({
                    params: params
                  });

                  this._updateButtonEnable();
                }}>
                  <option value="0">{state.chain.symbol}</option>
                  <option value="1">ERC20/BIP20</option>
                </Form.Control>
              </Form.Group> : null}

              {(state.params.type == 1) ? <Form.Group className="mb-3">
                <Form.Label>
                  {t('PersonalContractAddress')}
                </Form.Label>
                <Form.Control type="text" placeholder={t('PersonalContractAddressPlaceholder')} size="lg" onInput={(e) => {
                  const params = this.state.params;
                  params.token = e.target.value;
                  this.setState({
                    params: params
                  });
                  console.log("input contract = " + e.target.value);
                }} onChange={async (e) => {
                  // check contract address is valid or not
                  const params = this.state.params;
                  params.token = e.target.value;

                  console.log("change contract = " + e.target.value);

                  if (/^0x[a-fA-F0-9]{40}$/.test(params.token)) {
                    // valid
                    // params.symbol = "Token";
                    // params.decimals = 18;
                    if (state.provider) {
                      const info = await getERC20Info(state.provider, params.token);
                      params.symbol = info.symbol;
                      params.decimals = info.decimals;

                      // log symbol
                      console.log("symbol = " + params.symbol);
                      // log decimals
                      console.log("decimals = " + params.decimals);
                    }
                    this.setState({
                      params: params
                    });
                  }

                  this._updateButtonEnable();
                }}
                  isInvalid={state.params.token && !/^0x[a-fA-F0-9]{40}$/.test(state.params.token)}
                  isValid={state.params.token && /^0x[a-fA-F0-9]{40}$/.test(state.params.token)}
                />
              </Form.Group> : null}

              {(state.params.type == 1 && state.params.symbol) ? <div>
                <Alert variant="success">
                  <span style={{ color: '#000' }}>Token: </span><span style={{ fontStyle: 'bold' }}> {state.params.symbol}</span> <span style={{ color: '#000' }}>Contract:</span> [<a ref='noreferrer' target="_blank" href={state.chain.explorer + '/token/' + state.params.token}>{state.params.token.replace(/^(.{6})(.*)(.{4})$/, "$1...$3")}</a>]
                </Alert>
              </div> : null}

              <Form.Group className="mb-3">
                <Form.Label>Amount</Form.Label>
                <Form.Control type="number" size="lg" placeholder="金额"

                  isInvalid={state.params.amount && !/^\d+(\.\d+)?$/.test(state.params.amount)}
                  isValid={state.params.amount && /^\d+(\.\d+)?$/.test(state.params.amount)}
                  onChange={(e) => {
                    const params = this.state.params;
                    params.amount = e.target.value;
                    this.setState({
                      params: params
                    });
                    this._updateButtonEnable();
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>SKU Name</Form.Label>
                <Form.Control type="text" size="lg" placeholder="商品名称"
                  isInvalid={state.params.title && !/^[0-9a-zA-Z\u4e00-\u9fa5]+$/.test(state.params.title)}
                  isValid={state.params.title && /^[0-9a-zA-Z\u4e00-\u9fa5]+$/.test(state.params.title)}
                  onChange={(e) => {
                    const params = this.state.params;
                    params.title = e.target.value;
                    this.setState({
                      params: params
                    });
                    this._updateButtonEnable();
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control type="text" size="lg" placeholder="商品简介"
                  isInvalid={state.params.desc && !/^[0-9a-zA-Z\u4e00-\u9fa5]+$/.test(state.params.desc)}
                  isValid={state.params.desc && /^[0-9a-zA-Z\u4e00-\u9fa5]+$/.test(state.params.desc)}
                  onChange={(e) => {
                    const params = this.state.params;
                    params.desc = e.target.value;
                    this.setState({
                      params: params
                    });
                    this._updateButtonEnable();
                  }}
                />
              </Form.Group>
            </Form>
            <div className="d-grid gap-2" style={{ marginTop: '2rem' }}  >
              <Button variant="primary" size="lg" disabled={!state.buttonEnable}
                onClick={(e) => {
                  this._generateUrl();
                }}
              >
                {t('PersonalGenerateLink')}
              </Button>
            </div>
          </div>
        </div>
        {state.showUrl ? <div
          style={{
            position: 'fixed',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            zIndex: '100',
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
        ><div style={{
          padding: "1rem",
          position: 'fixed',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: '101',
          width: '90%',
          wordBreak: 'break-all',
        }}>
            <Alert variant="success">
              <Alert.Heading>
                {t('PersonalGenerateLinkSuccess')}
              </Alert.Heading>
              <p>
                <a rel='noreferrer' target="_blank" href={state.url}>{state.url}</a>
              </p>
              {state.showQRCode ? <center>
                <QRCode value={state.url}
                  size={200} fgColor="#000000" bgColor="#ffffff"
                />
              </center> : null}
              <hr />
              <div className="d-flex justify-content-end">
                {/* 复制 */}
                <Button variant="outline-success" onClick={(e) => {
                  copy(state.url);

                  // show alert
                  alert(t('PersonalCopyLinkSuccess'));
                }}>
                  {t('PersonalCopyLink')}
                </Button>
                <div style={{ width: '1rem' }}></div>
                {/* 二维码 */}
                {/* <Button variant="outline-success" onClick={(e) => {
                                    this.setState({
                                        showQRCode: !state.showQRCode,
                                    });
                                }}>
                                    {state.showQRCode ? "隐藏二维码" : "显示二维码"}
                                </Button>
                                <div style={{ width: '1rem' }}></div> */}
                {/* 关闭 */}
                <Button onClick={() => {
                  this.setState({
                    showUrl: false,
                  });
                }} variant="outline-success">
                  {t('PersonalClose')}
                </Button>
              </div>
            </Alert>
          </div></div> : null}
        <div style={{
          height: '5rem'
        }}></div>
        <div style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
        }}>
          <Bottom />
        </div>
      </div>
    );
  }
}

function CreatePaymentPage() {
  // get params from url
  const { t, i18n } = useTranslation();
  const [params] = useSearchParams();

  if (!params.has("mch")) {
    window.setTimeout(() => {
      window.location.href = "/payment/newmch";
    }, 1000);
    return <div></div>;
  }

  // chainId
  const chainId = params.get("chainId");
  if (!chainId) {
    window.setTimeout(() => {
      window.location.href = "/payment/newmch";
    }, 1000);
    return <div></div>;
  }

  params['type'] = '0'
  return <_CreatePaymentPage
    // params={params}
    params={
      {
        mch: params.get("mch"),
        type: params.get("type"),
        chainId: params.get("chainId"),
      }
    }
    t={t}
  />;
}

export default CreatePaymentPage;