import React from "react";

import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Accordion from 'react-bootstrap/Accordion';
import Bottom from "../components/common/Bottom";
import { useTranslation } from 'react-i18next';
import '../i18n.js'
import Header from "../components/common/Header";

class NewPageDetail extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;

        return (
            <div>
                <Header />
                <div style={{
                    padding: "1rem",
                }}>
                    <Card>
                        <Card.Header>{t('NewTitle')}</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                {t('NewLine1')}
                            </Card.Text>
                            <Card.Text>
                                {t('NewLine2')}
                            </Card.Text>
                            <Card.Text>
                                {t('NewLine3')}
                            </Card.Text>
                            <Card.Text>
                                {t('NewLine4')}
                            </Card.Text>
                            <Card.Text>
                                {t('NewLine5')}
                            </Card.Text>

                        </Card.Body>
                    </Card>
                    <div style={{ height: '1rem' }}></div>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                {t('NewPersonal')}
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    {t('NewPersonalLine1')}
                                </p>
                                <p>
                                    {t('NewPersonalLine2')}
                                </p>
                                <p>
                                    {t('NewPersonalLine3')}
                                </p>
                                <Button variant="primary" onClick={(e) => {
                                    window.location.href = "/new/personal";
                                }}>
                                    {t('NewPersonalButton')}
                                </Button>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                {t('NewOrder')}
                            </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    Building...
                                </div>
                                {/* <div>
                                    <Button variant="link">
                                        如何生成指定订单的收款链接
                                    </Button>
                                </div>
                                <div>
                                    <Button variant="link">
                                        如何对接订单收款
                                    </Button>
                                </div> */}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <Bottom />
            </div>
        );
    }
}

function NewPage() {

    // i18n
    const { t, i18n } = useTranslation();

    return (
        <NewPageDetail t={t} i18n={i18n} />
    );
}

export default NewPage;