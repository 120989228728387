import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from "react-bootstrap/Card";
import { Alert, Button, Form } from "react-bootstrap";

import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import '../i18n.js'
import { useNavigate } from 'react-router-dom';
// TLoading
import TLoading from "../components/common/TLoading.js";
// get from rest
import { CHAIN_CONFIG } from "../api/constents.js";

// Connector
import Connect from "../components/common/Connect.js";
import Bottom from "../components/common/Bottom.js";

// QRCode
import QRCode from 'qrcode.react';
// import copy
import copy from 'copy-to-clipboard';

// Header
import Header from "../components/common/Header.js";




import {
    connect,
    conn,
    getERC20Info,
    switchNetwork,
    isInWeb3Browser
} from "../api/dapp.js";

import { ethers } from "ethers";
import Deeplink from "../components/common/Deeplink.js";

class IndexPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            address: null,
            chainName: 'Loading...',
            chainId: -1,
            loading: false,
            currency0: {
                symbol: '-',
                decimals: -1,
            },
            currency1: {
                symbol: '-',
                decimals: -1,
            },
            balance0: '-',
            balance1: '-',
            amount: 'Loading...',
            errorChain: false,
            chain: {},
            isInWeb3Browser: false,
            showQRCode: false,

            writeToBlockChain: true,
            buttonDisabled: props.params.amount && props.params.amount > 0 ? false : true,

        };

    }

    componentDidMount() {
        this._checkBrowser();
        this._init();
    }

    doPay = async () => {
        const { t } = this.props;
        if (this.props.params.type == 1) {
            // transfer token
            const res = await connect();
            const provider = res.provider;
            const signer = await provider.getSigner();
            const contract = new ethers.Contract(this.props.params.contract, [
                "function transfer(address to, uint amount) public returns (bool)",
            ], signer);

            let amount = this.props.params.amount;
            if (!amount || amount <= 0) {
                if (!this.state.amount || this.state.amount <= 0) {
                    alert(t('IndexInvalidAmount'));
                    return;
                }
                console.log('currency1.decimals', this.state.currency1.decimals)
                // amount
                console.log('this.state.amount', this.state.amount)
                amount = ethers.parseUnits(this.state.amount, this.state.currency1.decimals);
            }
            try {
                const tx = await contract.transfer(this.props.params.to, amount);
                // tx hash
                console.log(tx.hash);
                this.props.onTx && this.props.onTx(tx);
            } catch (e) {
                console.log(e);
                alert(t('IndexTransactionFailed') + ":" + e.reason);
            }

        } else {
            // transfer eth
            const res = await connect();
            const provider = res.provider;
            const signer = await provider.getSigner();

            let amount = this.props.params.amount;
            if (!amount || amount <= 0) {
                if (!this.state.amount || this.state.amount <= 0) {
                    alert("IndexInvalidAmount");
                    return;
                }
                amount = ethers.parseUnits(this.state.amount, this.state.currency0.decimals);
            }

            try {
                const tx = await signer.sendTransaction({
                    to: this.props.params.to,
                    value: amount,
                });
                // 
                console.log(tx.hash);
                this.props.onTx && this.props.onTx(tx);
            } catch (e) {
                console.log(e);
                alert(t('IndexTransactionFailed') + ":" + e.reason);
            }
        }
    }

    _checkBrowser = async () => {
        const res = isInWeb3Browser();
        this.setState({
            isInWeb3Browser: res,
        });
        if (!res) {
            const decimals = this.props.params.decimals || 18;
            const symbol = this.props.params.symbol || 'Unknown';
            const amount = ethers.formatUnits(this.props.params.amount, decimals);
            this.setState({
                amount: amount,
                currency0: {
                    symbol: symbol,
                    decimals: decimals,
                },
                currency1: {
                    symbol: symbol,
                    decimals: decimals,
                },
            });
        }

    }

    _init = async () => {
        let res = await conn()

        // check 
        this._checkChainId(res.chainId);
        // res
        console.log('res:', res);
        this.setState({
            errorChain: this.props.params.chainId != res.chainId,
        });

        this.setState({
            // address: res.address,
            chainId: res.chainId,
        }, () => {
            this._initChainConfig(res.chainId);

            // console. chain id and params.chainId


            this._initBalance(res);
        });
    }

    _initBalance = async (res) => {

        if (!res.address) {
            console.log("address is null");
            const decimals = this.props.params.decimals || 18;
            console.log("decimals = " + decimals);
            // params amount
            console.log("params amount = " + this.props.params.amount);
            const amount = ethers.formatUnits(this.props.params.amount, parseInt(decimals));
            console.log("amount = " + amount);

            // symbol1
            const symbol = this.props.params.symbol || 'Unknown';
            console.log("symbol = " + symbol);

            this.setState({
                amount: amount,
                currency1: {
                    symbol: symbol,
                    decimals: decimals,
                },
            });
            return;
        }

        let cid = res.chainId
        // 如果res.chainId是16进制

        // if ( === 'string' && res.chainId.startsWith('0x')) {
        //     cid = parseInt(res.chainId, 16);
        // }
        if (this.props.params.chainId != cid) {
            console.log("chainId not match");
            const decimals = this.props.params.decimals || 18;
            const symbol = this.props.params.symbol || 'Unknown';
            const amount = (this.props.params.amount && this.props.params.amount > 0) ? ethers.formatUnits(this.props.params.amount, decimals) : 0;
            this.setState({
                amount: amount,
                isInWeb3Browser: false,
                currency0: {
                    symbol: symbol,
                    decimals: decimals,
                },
                currency1: {
                    symbol: symbol,
                    decimals: decimals,
                },
            });
            return;
        }

        if (this.props.params.type == 1) {
            // ERC20
            const info = await getERC20Info(
                res.provider,
                this.props.params.contract,
                res.address
            );

            const amount = (this.props.params.amount && this.props.params.amount > 0) ? ethers.formatUnits(this.props.params.amount, info.decimals) : 0;

            this.setState({
                currency1: {
                    symbol: info.symbol,
                    decimals: info.decimals,

                },
                amount: amount, //ethers.formatUnits(this.props.params.amount, info.decimals),
                balance1: ethers.formatUnits(info.balance, info.decimals),
            });
        } else {
            const amount = (this.props.params.amount && this.props.params.amount > 0) ? ethers.formatEther(this.props.params.amount) : 0;
            // eth
            this.setState({
                amount: amount, //ethers.formatEther(this.props.params.amount),
            });
        }

        // eth balance
        let balance = await res.provider.getBalance(res.address);

        this.setState({
            balance0: ethers.formatEther(balance),
        });

    }

    _onChainSwitch = async (chainId) => {
        console.log("chainId = " + chainId);
        this._init();
    }

    _onAccountChange = async (accounts) => {
        console.log("accounts = " + accounts);
        this._init()
    }

    _onConnect = async (res) => {
        this._initChainConfig(res.chainId);
        // 如果当前chainId和参数中的chainId不一致，那么需要切换chainId，弹出提示，切换chainId
        this._checkChainId(res.chainId);

        this._initBalance(res);

        this.setState({
            loading: false,
        });


    }

    _checkChainId = async (chainId) => {
        this.setState({
            errorChain: this.props.params.chainId != chainId,
        });

        const that = this
        const { t } = this.props;

        if (this.props.params.chainId != chainId) {
            if (!window.confirm(t('IndexErrorNetwork'))) {
                return;
            }
            const targetChain = CHAIN_CONFIG.find((chain) => {
                return chain.chainId == this.props.params.chainId;
            });
            await switchNetwork(targetChain, () => {
                console.log("switch success");
                that._init();
            });
        }
    }

    _initChainConfig = async (chainId) => {
        console.log("chainId = " + chainId);
        for (const chain of CHAIN_CONFIG) {
            if (chain.chainId == chainId) {
                this.setState({
                    chainName: chain.name,
                    currency0: {
                        symbol: chain.symbol,
                        decimals: chain.decimals,
                    },
                    chain: chain,
                });
                break;
            }
        }


    }

    render() {
        const { t, i18n } = this.props;
        const state = this.state;
        const props = this.props;
        return (
            <div>
                <Header title={t('AppName')} />
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // minHeight: "100vh",
                    backgroundColor: "#f5f5f5",
                }}>
                    <div style={{
                        padding: "0.5rem",
                        maxWidth: "600px",
                        display:'unset'
                    }}>
                        <Container>
                            <Row >
                                <Col>
                                    <div style={{ height: '1rem' }}></div>
                                    {state.isInWeb3Browser ? <div> <Card>
                                        <Card.Body>
                                            <div>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",

                                                }}>
                                                    <div style={{
                                                        color: state.errorChain ? 'red' : "green",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }} >
                                                        <img src={state.chain.icon} style={{
                                                            width: '1.8rem',
                                                            height: '1.8rem',
                                                        }}></img><div style={{ marginLeft: '0.5rem' }}>{this.state.chainName}</div>
                                                    </div>
                                                    <div>
                                                        <Connect onAccountChange={this._onAccountChange}
                                                            onChainSwitch={this._onChainSwitch}
                                                            onConnect={this._onConnect}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ height: '1rem' }}>

                                                </div>
                                                <div>
                                                    {state.balance0} {state.currency0.symbol}
                                                </div>
                                                {/* if params.type===1 then show  */}
                                                {(props.params.type == 1) ? <div>

                                                    <div>
                                                        {state.balance1} {state.currency1.symbol}
                                                    </div>
                                                </div> : null}
                                            </div>
                                        </Card.Body>
                                    </Card> </div> : null}
                                    <div style={{ height: '1rem' }}></div>
                                    <Card>
                                        <Card.Body>
                                            {props.params.amount && props.params.amount > 0 ? <div>
                                                <div style={{
                                                }}>
                                                    {t('IndexTitle')}
                                                </div>
                                                <div style={{
                                                    fontSize: "2.2rem",
                                                    textAlign: "center",
                                                    fontWeight: "bold",
                                                    marginTop: "1rem",
                                                    marginBottom: "1rem",
                                                }}>
                                                    {state.amount} {props.params.type == 1 ? state.currency1.symbol : state.currency0.symbol}
                                                </div>
                                            </div> : <div>
                                                {/*input amount  */}
                                                <Form.Group className="mb-3" >
                                                    <Form.Control type="number" size="lg" placeholder={t('IndexAmountInputPlaceholder')} onChange={(e) => {
                                                        // buttonDisabled
                                                        if (!e.target.value || e.target.value <= 0) {
                                                            this.setState({
                                                                buttonDisabled: true,
                                                            });
                                                        } else {
                                                            this.setState({
                                                                buttonDisabled: false,
                                                            });
                                                        }
                                                        this.setState({
                                                            amount: e.target.value,
                                                        });
                                                    }}
                                                        isValid={state.amount && state.amount > 0}
                                                        isInvalid={!state.amount || state.amount <= 0}
                                                    />
                                                </Form.Group>
                                            </div>}
                                            {props.params.type == 1 ? <div style={{ textAlign: 'center', color: '#666666' }}>
                                                [{props.params.type == 1 ? state.currency1.symbol : state.currency0.symbol} 合约：<span><a href={state.chain.explorer + '/address/' + props.params.contract} rel="noreferrer" target="_blank">{props.params.contract.replace(/^(.{8}).+(.{6})$/, "$1...$2")}</a></span>]
                                            </div> : null}
                                            <div style={{ marginTop: '1rem', color: '#666666' }}>
                                                {t('IndexTo')}
                                            </div>
                                            <div style={{
                                                marginBottom: "1rem",
                                                wordBreak: "break-all",
                                            }}>
                                                <Alert variant="secondary">
                                                    <div><b>{props.params.to}</b></div>
                                                    <div style={{ textAlign: 'right' }}>
                                                        [<span style={{ color: 'blue' }} onClick={(e) => {
                                                            copy(props.params.to);
                                                            alert(t('IndexCopySuccess'));
                                                        }}>{t('IndexCopyAddress')}</span>]
                                                        <span>&nbsp;&nbsp;</span>
                                                        [<span style={{ color: 'blue' }} onClick={(e) => {
                                                            this.setState({
                                                                showQRCode: true,
                                                            });
                                                        }}>{t('IndexQRCode')}</span>]
                                                    </div>
                                                </Alert>
                                            </div>
                                            <div>
                                                <div style={{ color: '#666666' }}>{t('IndexThisAddressIsMarkedAs')}</div>
                                                <div style={{
                                                    color: "orange",
                                                }}>{t('IndexThisAddressIsMarkedAsNone')}</div>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginTop: '1rem',
                                            }}>
                                                <div style={{ color: '#666666' }}>
                                                    {t('IndexTransactionMemo')}
                                                </div>

                                            </div>
                                            <div>
                                                {props.params.message || '-'}
                                            </div>


                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={{ marginTop: '1rem' }}>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {(state.isInWeb3Browser) ? <div className="d-grid gap-2" style={{ marginTop: '0.6rem' }}>
                                        <Button variant="primary"
                                            disabled={state.buttonDisabled}
                                            size="lg" onClick={(e) => {
                                                this.doPay();
                                            }}>
                                            {t('ButtonPay')}
                                        </Button>
                                    </div> : <div style={{ color: 'red', marginTop: '1rem' }}>
                                        <Alert variant="danger">
                                            {t('IndexNotSupportLine1')}
                                            <div>
                                                {t('IndexNotSupportLine2')}
                                            </div>
                                            <div>
                                                {t('IndexNotSupportLine3')}
                                            </div>
                                        </Alert>
                                    </div>}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={{ marginTop: '1rem' }}>
                                        {t('IndexYouCanAlso')}
                                    </div>
                                </Col>
                            </Row>
                            <Deeplink />

                            <Row>
                                <Col>
                                    {t('IndexYouCanAlsoToo')}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="d-grid gap-2" style={{ marginTop: '0.6rem' }}>
                                        <Button variant="light" size="lg" onClick={(e) => {
                                            copy(window.location.href);
                                            alert(t('IndexCopySuccess'));
                                        }}>
                                            {t('IndexCopyThisPage')}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            {/* <Row>
                            <Col>
                                <div className="d-grid gap-2" style={{ marginTop: '0.6rem' }}>
                                    <Button variant="info" size="lg" onClick={(e) => {
                                        copy(props.params.to);
                                        alert("复制成功");
                                    }}>
                                        复制地址进行转账
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="d-grid gap-2" style={{ marginTop: '0.6rem' }}>
                                    <Button variant="info" size="lg" onClick={(e) => {
                                        this.setState({
                                            showQRCode: true,
                                        });
                                    }}>
                                        通过二维码转账
                                    </Button>
                                </div>
                            </Col>
                        </Row> */}
                            <Row>
                                <Col>
                                    <div className="d-grid gap-2" style={{ marginTop: '0.6rem' }}>
                                        <Button variant="light" size="lg" onClick={(e) => {
                                            // a href = /new
                                            window.location.href = '/new';
                                        }}>
                                            {t('IndexCreateMyPage')}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Bottom />
                <TLoading show={this.state.loading} />
                <div style={{
                    // mask
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    zIndex: '100',
                    display: this.state.showQRCode ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                }} onClick={(e) => {
                    this.setState({
                        showQRCode: false,
                    });
                }}>
                    <div style={{
                        backgroundColor: '#ffffff',
                        padding: '1rem',
                        borderRadius: '0.5rem',
                        width: '80%',
                        // center
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',

                    }}>
                        <QRCode value={props.params.to}
                            size={256} fgColor="#000000" bgColor="#ffffff"
                        />
                        <div style={{
                            wordBreak: 'break-all',
                        }}>
                            {props.params.to}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function IndexPageExport() {
    const [params] = useSearchParams();
    // const navigate = useNavigate();

    const { t, i18n } = useTranslation();
    // i18n 可以用来changeLanguage
    const navigate = useNavigate();

    if (!params.get('to')) {
        window.setTimeout(() => {
            navigate('/new');
        }, 100);
        return null;
    }

    return (
        <div>
            <IndexPage params={{
                to: params.get('to'),
                amount: params.get('amount') || 0,
                lang: params.get('lang') || 'en',
                message: params.get('message'),
                chainId: params.get('chainId'),
                type: params.get('type'),
                contract: /^0x[0-9a-fA-F]{40}$/.test(params.get('contract')) ? params.get('contract') : null,
                decimals: params.get('decimals'),
                symbol: params.get('symbol'),
                re: params.get('re'),
            }} t={t} i18n={i18n} onTx={(tx) => {
                navigate('/result', {
                    state: {
                        txHash: tx.hash,
                        chainId: params.get('chainId'),
                        re: params.get('re'),
                    }
                });
            }} />
        </div>
    );
}

export default IndexPageExport;